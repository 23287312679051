import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";

import Drawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import NotificationsIcon from "@mui/icons-material/Notifications";

// icons
import DashboardIcon from "@mui/icons-material/Dashboard";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EmailIcon from "@mui/icons-material/Email";
import LogoutIcon from "@mui/icons-material/Logout";
import OrdersIcon from "@mui/icons-material/Inventory2";
import { GrTransaction as TransactionIcon } from "react-icons/gr";

import { Avatar, Badge, Button, MenuItem } from "@mui/material";
import { Box } from "@mui/system";
import { NavLink } from "react-router-dom";
import StyledMenu from "../StyledMenu";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { logoutUser, selectUser } from "../../features/user/userSlice";
import { useSelector } from "react-redux";
import { userRole } from "../../constants/roles";
import { protectedRoutesConditions } from "../../constants/protectedRoutesCondition";

const drawerWidth = 340;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const SideBar = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const openDrawer = () => setOpen(true);
  const closeDrawer = () => setOpen(false);
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    dispatch(logoutUser());
  };

  return (
    <>
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={openDrawer}
            edge="start"
            sx={{
              mr: 2,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Dashboard
          </Typography>
          <Badge color="warning" badgeContent="9+" sx={{ mr: 3 }}>
            <IconButton color="inherit" aria-label="open drawer" edge="start">
              <NotificationsIcon />
            </IconButton>
          </Badge>
          <Avatar
            alt="Avatar"
            onClick={handleClick}
            sx={{ cursor: "pointer" }}
          />
          <StyledMenu
            anchorEl={anchorEl}
            open={anchorEl ? true : false}
            onClose={handleClose}
          >
            <MenuItem disableRipple>
              <EmailIcon />
              {user?.userEmail}
            </MenuItem>
            <MenuItem disableRipple>
              <AccountCircleIcon />
              Profile
            </MenuItem>
            <Divider />
            <MenuItem
              disableRipple
              onClick={() => {
                handleClose();
                logout();
              }}
            >
              <LogoutIcon />
              Logout
            </MenuItem>
          </StyledMenu>
        </Toolbar>
        {/* <Toolbar>
          <Box display="flex" alignItems="center">
          </Box>
        </Toolbar> */}
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="temporary"
        anchor="left"
        open={open}
        onClose={closeDrawer}
      >
        <DrawerHeader>
          <IconButton onClick={closeDrawer}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem disablePadding>
            <NavLink
              to="/dashboard"
              className={({ isActive }) => `${isActive ? "nav-active" : ""}`}
              style={{
                width: "100%",
              }}
            >
              <ListItemButton onClick={closeDrawer}>
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
              </ListItemButton>
            </NavLink>
          </ListItem>
          {protectedRoutesConditions(user).WITHDRAW_ROUTE && (
            <ListItem disablePadding>
              <NavLink
                to="/withdraw"
                className={({ isActive }) => `${isActive ? "nav-active" : ""}`}
                style={{
                  width: "100%",
                }}
              >
                <ListItemButton onClick={closeDrawer}>
                  <ListItemIcon>
                    <AnalyticsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Withdraw" />
                </ListItemButton>
              </NavLink>
            </ListItem>
          )}
          {protectedRoutesConditions(user).ORDER_ROUTE && (
            <ListItem disablePadding>
              <NavLink
                to="/orders"
                className={({ isActive }) => `${isActive ? "nav-active" : ""}`}
                style={{
                  width: "100%",
                }}
              >
                <ListItemButton onClick={closeDrawer}>
                  <ListItemIcon>
                    <OrdersIcon />
                  </ListItemIcon>
                  <ListItemText primary="Orders" />
                </ListItemButton>
              </NavLink>
            </ListItem>
          )}
          {protectedRoutesConditions(user).TRANSACTION_ROUTE && (
            <ListItem disablePadding>
              <NavLink
                to="/transaction"
                className={({ isActive }) => `${isActive ? "nav-active" : ""}`}
                style={{
                  width: "100%",
                }}
              >
                <ListItemButton onClick={closeDrawer}>
                  <ListItemIcon>
                    <TransactionIcon />
                  </ListItemIcon>
                  <ListItemText primary="Transaction" />
                </ListItemButton>
              </NavLink>
            </ListItem>
          )}
        </List>
      </Drawer>
      <DrawerHeader />
    </>
  );
};

export default SideBar;
