import { api } from ".";

export const signUp = async (formData, referral) => {
  try {
    const { data } = await api({
      url: `api/v1/users/signup${referral ? `?ReferredBy=${referral}` : ""}`,
      method: "post",
      data: formData,
    });
    console.log(data);
    return {
      error: false,
      data,
    };
  } catch (error) {
    console.log(error);
    return {
      error: true,
      data: error,
    };
  }
};

export const loginWithEmailandOTP = async (email, otp) => {
  try {
    const { data, status } = await api({
      url: `api/v1/admin/login?type=EMAIL&email=${email}&otp=${otp}&mode=OTP`,
      method: "post",
    });
    return {
      data,
      status,
    };
  } catch (error) {
    return {
      error,
      message: error?.response?.data?.message,
    };
  }
};
