import { Box, Typography } from "@mui/material";
import React from "react";

const Dashboard = () => {
  return (
    <Box
      sx={{
        padding: {
          xs: 1,
          sm: 2,
        },
      }}
    >
      <Typography>Dashboard</Typography>
    </Box>
  );
};

export default Dashboard;
