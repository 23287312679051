import { Box, CircularProgress, Skeleton } from "@mui/material";
import React from "react";

const DrawerLoading = () => {
  return (
    <Box
      height="calc(100vh - 96px)"
      display={"flex"}
      flexDirection="column"
      gap="20px"
    >
      <Skeleton
        variant="rounded"
        width="100%"
        sx={{ bgcolor: "grey.300" }}
        animation="wave"
        height={81}
      />
      <Skeleton
        variant="rounded"
        width="100%"
        sx={{ bgcolor: "grey.300" }}
        animation="wave"
        height={300}
      />
      <Skeleton
        variant="rounded"
        width="100%"
        sx={{ bgcolor: "grey.300" }}
        animation="wave"
        height={300}
      />
    </Box>
    // <Box
    //   display={"flex"}
    //   width="100%"
    //   height="calc(100vh - 96px)"
    //   alignItems="center"
    //   justifyContent="center"
    // >
    //   <CircularProgress color="primary" />
    // </Box>
  );
};

export default DrawerLoading;
