import { api } from "..";

export const getFundsObligations = async (token, obligationId) => {
  try {
    const { data } = await api({
      url: `/api/v1/admin/fundObligations/${obligationId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return {
      data,
    };
  } catch (error) {
    return {
      error,
    };
  }
};
