import { api } from "..";

//this returns a response of type PDF
export const getEOIForOrders = async (token, orderId, version = 1) => {
  try {
    const { data } = await api({
      url: `/api/v1/orderContract/download?orderId=${orderId}&version=${version}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: "blob",
    });
    return {
      data,
    };
  } catch (error) {
    return {
      error,
    };
  }
};
