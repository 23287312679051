export const userRole = {
  ADMIN: "ROLE_ADMIN",
  ACTUATOR: "ROLE_ACTUATOR",
  CUSTODIAN: "ROLE_CUSTODIAN",
  CLIENT: "ROLE_CLIENT",
  ACTUATOR: "ROLE_ACTUATOR",
  CUSTODIAN: "ROLE_CUSTODIAN",
  CLIENT: "ROLE_CLIENT",
  DEALER: "ROLE_DEALER",
};
