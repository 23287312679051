import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { loginUser, selectUser } from "./features/user/userSlice";

// material
import { CircularProgress, Box } from "@mui/material";

// layout components
import SideBar from "./components/layouts/Sidebar";

// pages
import Dashboard from "./pages/Dashboard";
import Home from "./pages/Home";
import Register from "./pages/Register";
import Withdraw from "./pages/Withdraw";
import Transaction from "./pages/Transaction";
import GlobalSnackbar from "./components/GlobalSnackbar";
import Orders from "./pages/Orders";
import { userRole } from "./constants/roles";
import { protectedRoutesConditions } from "./constants/protectedRoutesCondition";

const App = () => {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const userFromLS = JSON.parse(localStorage.getItem("user"));
    if (userFromLS) {
      dispatch(
        loginUser({
          ...userFromLS,
          token: localStorage.getItem("token") || null,
        })
      );
    } else {
      dispatch(loginUser(null));
    }
    setIsLoading(false);
  }, []);
  return (
    <>
      {isLoading ? (
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            width: "100vw",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {user && <SideBar />}
          <Routes>
            <Route
              path="/"
              element={user ? <Home /> : <Navigate replace to="/register" />}
            />
            <Route
              path="/register"
              element={
                user ? <Navigate replace to="/dashboard" /> : <Register />
              }
            />
            <Route
              path="/dashboard"
              element={
                user ? <Dashboard /> : <Navigate replace to="/register" />
              }
            />
            <Route
              path="/withdraw"
              element={
                protectedRoutesConditions(user).WITHDRAW_ROUTE ? (
                  <Withdraw />
                ) : (
                  <Navigate replace to="/register" />
                )
              }
            />
            <Route
              path="/orders"
              element={
                protectedRoutesConditions(user).ORDER_ROUTE ? (
                  <Orders />
                ) : (
                  <Navigate replace to="/register" />
                )
              }
            />
            <Route
              path="/transaction"
              element={
                protectedRoutesConditions(user).TRANSACTION_ROUTE ? (
                  <Transaction />
                ) : (
                  <Navigate replace to="/register" />
                )
              }
            />
          </Routes>
        </>
      )}
      <GlobalSnackbar />
    </>
  );
};

export default App;
