import { getEOIForOrders } from "../axios/transaction/getEOIForOrders";

export const get_download_signed_document = async (token, orderId) => {
    let response = await getEOIForOrders(token=token, orderId=orderId);
    console.log(response)
    if (response.error) {
    console.log("error occured while trying to download eoi");
    console.log(response.error);
    } else {
      //TODO download blob

      // set the blog type to final pdf
      const file = new Blob([response.data], { type: "application/pdf" });

      // process to auto download it
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = fileURL;
      link.download = orderId + "_signed.pdf";
      link.click();
    }
  };


