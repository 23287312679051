import { userRole } from "./roles";

export const protectedRoutesConditions = (user) => ({
  WITHDRAW_ROUTE:
    user &&
    user?.roles?.includes(userRole.ADMIN) ||
    user?.roles?.includes(userRole.CUSTODIAN),
  ORDER_ROUTE: user && user?.roles?.includes(userRole.DEALER),
  TRANSACTION_ROUTE:
    user &&
    user?.roles?.includes(userRole.ADMIN) ||
    user?.roles?.includes(userRole.CUSTODIAN),
});
