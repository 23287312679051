import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fundObligationsPayout } from "../axios/transaction/fundObligationsPayout";
import { getFundObligationsPaymentDetails } from "../axios/transaction/getFundsObligationPaymentDetails";
import { getPaymentDetails } from "../axios/transaction/getPaymentDetails";
import { selectUser } from "../features/user/userSlice";
import { formatAmount, formatDate } from "../utils";
import { showSnackbar } from "../features/snackbarSlice";
import CustomDrawer from "./layouts/CustomDrawer";
import DrawerAccordion from "./layouts/DrawerAccordion";

import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { get_download_signed_document } from "./DrawersHelper";
import StyledMenu from "./StyledMenu";
import DrawerLoading from "./layouts/DrawerLoading";

// icons
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import DownloadIcon from "@mui/icons-material/Download";
import LoopIcon from "@mui/icons-material/Loop";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// api
import { api } from "../axios";

export const Drawer1 = ({ open, title, close, data, drawerLoading }) => (
  <CustomDrawer open={open} title={title} onClose={close}>
    <DrawerAccordion
      title="Transaction Section"
      data={[
        {
          key: "Transaction ID",
          value: data?.data?.transactionId,
        },
        // ! creationTime nhi hai
        {
          key: "Creation Time",
          value: "TO BE DONE",
        },
        {
          key: "ISIN",
          value: data?.data?.isin,
        },
        {
          key: "Symbol",
          value: data?.data?.symbol,
        },
        {
          key: "Quantity",
          value: data?.data?.quantity,
        },
      ]}
    />
    <DrawerAccordion
      title="Ask Side"
      data={[
        {
          key: "Fill ID",
          value: data?.data?.askFill?.fillId,
        },
        {
          key: "Order Id",
          value: data?.data?.askFill?.orderId,
        },
        {
          key: "Order Version",
          value: data?.data?.askFill?.version,
        },
        {
          key: "Price(₹)",
          value: data?.data?.askFill?.price,
        },
        {
          key: "Share Obligation",
          value: data?.data?.askFill?.obligationId,
        },
        {
          key: "Status",
          value: data?.data?.askFill?.status,
        },
        {
          key: "User ID",
          value: data?.data?.askFill?.userId,
        },
        {
          key: "Is Obligation Fulfilled?",
          value: data?.data?.askFill?.isObligationFulfilled ? "Yes" : "No",
        },
      ]}
    />
    <DrawerAccordion
      title="Buy Side"
      data={[
        {
          key: "Fill ID",
          value: data?.data?.bidFill?.fillId,
        },
        {
          key: "Order Id",
          value: data?.data?.bidFill?.orderId,
        },
        {
          key: "Order Version",
          value: data?.data?.bidFill?.version,
        },
        {
          key: "Price(₹)",
          value: data?.data?.bidFill?.price,
        },
        {
          key: "Share Obligation",
          value: data?.data?.bidFill?.obligationId,
        },
        {
          key: "Status",
          value: data?.data?.bidFill?.status,
        },
        {
          key: "User ID",
          value: data?.data?.bidFill?.userId,
        },
        {
          key: "Is Obligation Fulfilled?",
          value: data?.data?.bidFill?.isObligationFulfilled ? "Yes" : "No",
        },
      ]}
    />
  </CustomDrawer>
);

export const Drawer2 = ({ open, title, close, data, drawerLoading }) => {
  const user = useSelector(selectUser);
  const [mailStatus, setMailStatus] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();

  const downloadEoi = () => {
    console.log("data", data);
    dispatch(
      showSnackbar({
        type: "success",
        message: "Downloading File....",
        autoHideDuration: 2000,
      })
    );
    get_download_signed_document(
      user.token,
      data?.selectedData?.data?.bidFill?.orderId
    );
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const sendRequestMail = async () => {
    dispatch(
      showSnackbar({
        type: "info",
        message: "Sending Mail....",
        autoHideDuration: 2000,
      })
    );
    try {
      const { data } = await api({
        url: `/api/v1/custodian/notifications/trigger/`,
        method: "post",
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
        data: {
          notificationId: mailStatus?.request?.communicationId,
        },
      });
      console.log(data);
      dispatch(
        showSnackbar({
          type: "success",
          message: "Mail Send",
          autoHideDuration: 2000,
        })
      );
      setMailStatus({
        ...mailStatus,
        request: {
          status: "SENT",
        },
      });
      handleClose();
    } catch (error) {
      console.log(error);
      dispatch(
        showSnackbar({
          type: "error",
          message: "Failed to send mail",
          autoHideDuration: 2000,
        })
      );
      handleClose();
    }
  };

  const sendAcknowledgementMail = async () => {
    dispatch(
      showSnackbar({
        type: "info",
        message: "Sending Mail....",
        autoHideDuration: 2000,
      })
    );
    try {
      const { data } = await api({
        url: `/api/v1/custodian/notifications/trigger/`,
        method: "post",
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
        data: {
          notificationId: mailStatus?.ack?.communicationId,
        },
      });
      console.log(data);
      dispatch(
        showSnackbar({
          type: "success",
          message: "Mail Send",
          autoHideDuration: 2000,
        })
      );
      setMailStatus({
        ...mailStatus,
        ack: {
          status: "SENT",
        },
      });
      handleClose();
    } catch (error) {
      console.log(error);
      dispatch(
        showSnackbar({
          type: "error",
          message: "Failed to send mail",
          autoHideDuration: 2000,
        })
      );
      handleClose();
    }
  };

  useEffect(() => {
    const getData = async () => {
      const { data: data1 } = await api({
        url: `/api/v1/custodian/notifications/client/obligations/funds/${data?.drawerData?.obligationId}`,
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      console.log(data1);
      setMailStatus(data1);
    };
    if (open && !drawerLoading) getData();
  }, [open, drawerLoading]);

  return (
    <CustomDrawer open={open} title={title} onClose={close}>
      {drawerLoading ? (
        <DrawerLoading />
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              alignItem: "center",
              justifyContent: "space-between",
              placeItems: "center",
              width: "100%",
            }}
          >
            <Typography
              variant="h4"
              fontWeight="bold"
              color="#333"
              padding="20px"
            >
              ₹ {formatAmount(data.drawerData?.amount)}
            </Typography>
            <Button
              id="demo-customized-button"
              aria-controls={anchorEl ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={anchorEl ? "true" : undefined}
              variant="contained"
              disableElevation
              onClick={handleClick}
              endIcon={<KeyboardArrowDownIcon />}
            >
              Options
            </Button>
          </Box>

          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={anchorEl}
            onClose={handleClose}
          >
            <MenuItem onClick={downloadEoi} disableRipple>
              <DownloadIcon />
              Download Buyer's EOI
            </MenuItem>
            <MenuItem
              onClick={sendRequestMail}
              disableRipple
              disabled={
                mailStatus?.request?.status === "NOT_SENT" ? false : true
              }
            >
              <AccountBalanceIcon />
              Send fund request mail
              {(mailStatus?.request?.status === "SENT" ||
                mailStatus?.request?.status === "NOT_REQUIRED") &&
                 " (" + mailStatus?.request?.status + ")"}
            </MenuItem>
            <Divider sx={{ my: 0.5 }} />
            <MenuItem
              onClick={sendAcknowledgementMail}
              disableRipple
              disabled={mailStatus?.ack?.status === "NOT_SENT" ? false : true}
            >
              <PriorityHighIcon />
              Send fund received acknowledgement mail
              {(mailStatus?.ack?.status === "SENT" ||
                mailStatus?.ack?.status === "NOT_REQUIRED") &&
                " (" + mailStatus?.ack?.status + ")"}
            </MenuItem>
          </StyledMenu>
          <Box
            sx={{
              maxHeight: "calc(100vh - 200px)",
              overflowY: "scroll",
              marginBottom: "20px",
            }}
          >
            <DrawerAccordion
              title="Fund Obligation Details"
              data={[
                {
                  key: "Obligation ID",
                  value: data.drawerData?.obligationId,
                },
                {
                  key: "Expected Amount(₹)",
                  value: formatAmount(data.drawerData?.amount),
                },
                {
                  key: "Amount Received(₹)",
                  value: formatAmount(data.drawerData?.amountTransferred),
                },
                {
                  key: "Status",
                  value: data.drawerData?.obligationStatus,
                },
                {
                  key: "Creation Time",
                  value: formatDate(data.drawerData?.creationTimestamp),
                },
                {
                  key: "Deadline",
                  value: formatDate(data.drawerData?.expiryTimestamp),
                },
                {
                  key: "Completion Time",
                  value: formatDate(data.drawerData?.obligationMeetingTime),
                },
                {
                  key: "Fill ID",
                  value: data.drawerData?.fillId,
                },
                {
                  key: "Closure ID",
                  value: data.drawerData?.closureId,
                },
                {
                  key: "Client’s Bank Account Number",
                  value:
                    data.drawerData?.sourceBankAccountId?.bankAccountNumber,
                },
                {
                  key: "Client’s Bank Account IFSC",
                  value: data.drawerData?.sourceBankAccountId?.ifsc,
                },
                {
                  key: "Type",
                  value: data.drawerData?.obligationUserType,
                },
              ]}
            />
            <DrawerAccordion
              title="Payment Details"
              data={data.drawerData?.linkedFundTransferTransactions}
              isTable={true}
              table={{
                columns: ["Transaction ID", "Amount(₹)"],
                rows:
                  data.drawerData?.linkedFundTransferTransactions?.map((obj) =>
                    Object.values(obj)
                  ) || [],
              }}
            />
          </Box>
        </>
      )}
    </CustomDrawer>
  );
};

export const Drawer3 = ({ open, title, close, data, drawerLoading }) => {
  const user = useSelector(selectUser);
  const [anchorEl, setAnchorEl] = useState(null);
  const [mailStatus, setMailStatus] = useState({});
  const dispatch = useDispatch();

  const downloadEoi = (event) => {
    console.log("data", data);
    dispatch(
      showSnackbar({
        type: "success",
        message: "Downloading File....",
        autoHideDuration: 2000,
      })
    );
    get_download_signed_document(
      user.token,
      data?.selectedData?.data?.askFill?.orderId
    );
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const sendRequestMail = async () => {
    dispatch(
      showSnackbar({
        type: "info",
        message: "Sending Mail....",
        autoHideDuration: 2000,
      })
    );
    try {
      const { data } = await api({
        url: `/api/v1/custodian/notifications/trigger/`,
        method: "post",
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
        data: {
          notificationId: mailStatus?.request?.communicationId,
        },
      });
      console.log(data);
      dispatch(
        showSnackbar({
          type: "success",
          message: "Mail Send",
          autoHideDuration: 2000,
        })
      );
      setMailStatus({
        ...mailStatus,
        request: {
          status: "SENT",
        },
      });
      handleClose();
    } catch (error) {
      console.log(error);
      dispatch(
        showSnackbar({
          type: "error",
          message: "Failed to send mail",
          autoHideDuration: 2000,
        })
      );
      handleClose();
    }
  };

  const sendAcknowledgementMail = async () => {
    dispatch(
      showSnackbar({
        type: "info",
        message: "Sending Mail....",
        autoHideDuration: 2000,
      })
    );
    try {
      const { data } = await api({
        url: `/api/v1/custodian/notifications/trigger/`,
        method: "post",
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
        data: {
          notificationId: mailStatus?.ack?.communicationId,
        },
      });
      console.log(data);
      dispatch(
        showSnackbar({
          type: "success",
          message: "Mail Send",
          autoHideDuration: 2000,
        })
      );
      setMailStatus({
        ...mailStatus,
        ack: {
          status: "SENT",
        },
      });
      handleClose();
    } catch (error) {
      console.log(error);
      dispatch(
        showSnackbar({
          type: "error",
          message: "Failed to send mail",
          autoHideDuration: 2000,
        })
      );
      handleClose();
    }
  };

  useEffect(() => {
    const getData = async () => {
      const { data: data1 } = await api({
        url: `/api/v1/custodian/notifications/client/obligations/shares/${data?.drawerData?.obligationId}`,
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      console.log(data1);
      setMailStatus(data1);
    };
    if (open && !drawerLoading) getData();
  }, [open, drawerLoading]);

  return (
    <CustomDrawer open={open} title={title} onClose={close}>
      {drawerLoading ? (
        <DrawerLoading />
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              alignItem: "center",
              placeItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography
              variant="h4"
              fontWeight="bold"
              color="#333"
              padding="20px"
            >
              Shares: {formatAmount(data.drawerData?.qty)}
            </Typography>
            <Box>
              <Button
                id="demo-customized-button"
                aria-controls={anchorEl ? "demo-customized-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={anchorEl ? "true" : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
              >
                Options
              </Button>
            </Box>
          </Box>

          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={anchorEl}
            onClose={handleClose}
          >
            <MenuItem onClick={downloadEoi} disableRipple>
              <DownloadIcon />
              Download Seller's EOI
            </MenuItem>
            <MenuItem
              onClick={sendRequestMail}
              disableRipple
              disabled={
                mailStatus?.request?.status === "NOT_SENT" ? false : true
              }
            >
              <PendingActionsIcon />
              Send share request mail
              {(mailStatus?.request?.status === "SENT" ||
                mailStatus?.request?.status === "NOT_REQUIRED") &&
                " (" + mailStatus?.request?.status + ")"}
            </MenuItem>
            <Divider sx={{ my: 0.5 }} />
            <MenuItem
              onClick={sendAcknowledgementMail}
              disableRipple
              disabled={mailStatus?.ack?.status === "NOT_SENT" ? false : true}
            >
              <ThumbUpAltIcon />
              Send share received acknowledgement mail
              {(mailStatus?.ack?.status === "SENT" ||
                mailStatus?.ack?.status === "NOT_REQUIRED") &&
                " (" + mailStatus?.ack?.status + ")"}
            </MenuItem>
          </StyledMenu>

          <Box
            sx={{
              maxHeight: "calc(100vh - 200px)",
              overflowY: "scroll",
              marginBottom: "20px",
            }}
          >
            <DrawerAccordion
              title="Share Obligation Details"
              data={[
                {
                  key: "Obligation ID",
                  value: data.drawerData?.obligationId,
                },
                {
                  key: "Expected Shares",
                  value: data.drawerData?.qty,
                },
                {
                  key: "Shares Received",
                  value: data.drawerData?.sharesTransferred,
                },
                {
                  key: "Status",
                  value: data.drawerData?.obligationStatus,
                },
                {
                  key: "Creation Time",
                  value: formatDate(data.drawerData?.creationTimestamp),
                },
                {
                  key: "Deadline",
                  value: formatDate(data.drawerData?.expiryTimestamp),
                },
                {
                  key: "Completion Time",
                  value: formatDate(data.drawerData?.obligationMeetingTime),
                },
                {
                  key: "Fill ID",
                  value: data.drawerData?.fillId,
                },
                {
                  key: "Closure ID",
                  value: data.drawerData?.closureId,
                },
                {
                  key: "Client’s DP ID[Source]",
                  value: data.drawerData?.sourceDPId,
                },
                {
                  key: "Client’s BO ID[Source]",
                  value: data.drawerData?.sourceBoId,
                },
                {
                  key: "FundsMap DP ID[Target]",
                  value: data.drawerData?.targetDPId,
                },
                {
                  key: "FundsMap BO ID[Target]",
                  value: data.drawerData?.targetBoId,
                },
                {
                  key: "Type",
                  value: data.drawerData?.obligationUserType,
                },
              ]}
            />
            <DrawerAccordion
              title="Payment Details"
              data={data.drawerData?.linkedShareTransactions}
              isTable={true}
              table={{
                columns: ["Transaction ID", "Quantity"],
                rows:
                  data.drawerData?.linkedShareTransactions?.map((obj) =>
                    Object.values(obj)
                  ) || [],
              }}
            />
          </Box>
        </>
      )}
    </CustomDrawer>
  );
};

export const Drawer4 = ({ open, title, close, data, drawerLoading }) => (
  <CustomDrawer open={open} title={title} onClose={close}>
    {drawerLoading ? (
      <DrawerLoading />
    ) : (
      <>
        <DrawerAccordion
          title="Closure Details"
          data={[
            {
              key: "Closure ID",
              value: data?.closureId,
            },
            {
              key: "Closure Type",
              value: data?.type,
            },
            {
              key: "Closure Status",
              value: data?.state,
            },
            {
              key: "Closure Creation Time",
              value: formatDate(data?.closureCreationTime),
            },
            {
              key: "Closure Completion Time",
              value: formatDate(data?.closureCompletionTime),
            },
            {
              key: "FundsMap Fund Obligation ID",
              value: data?.fundObligationResponseDto?.obligationId,
            },
            {
              key: "FundsMap Share Obligation ID",
              value: data?.shareObligationResponseDto?.obligationId,
            },
            {
              key: "Amount to be paid (₹)",
              value: formatAmount(data?.fundObligationResponseDto?.amount),
            },
            {
              key: "Shares to be transferred",
              value: data?.shareObligationResponseDto?.qty,
            },
            {
              key: "ISIN",
              value: data?.shareObligationResponseDto?.isin,
            },
            {
              key: "Closure Completed By User ID",
              value: data?.closureCompletedBy,
            },
          ]}
        />
      </>
    )}
  </CustomDrawer>
);
export const Drawer5 = ({ open, title, close, data, drawerLoading }) => {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const shouldPayoutButtonBeShown = checkIfFundPayoutButtonIsToBeShown(
    data?.drawerData?.state
  );
  const [processLoading, setProcessLoading] = useState(false);
  const [paymentData, setPaymentData] = useState(null);
  const states = ["PROCESSING", "CLOSED", "FULFILLED"];

  function checkIfFundPayoutButtonIsToBeShown(state) {
    const validStates = ["OPEN"];
    if (state && validStates.includes(state)) {
      return true;
    } else {
      return false;
    }
  }
  const getPaymentDetailsData = async () => {
    const { data: paymentDetails } = await getPaymentDetails(
      user.token,
      data?.drawerData?.obligationId
    );
    setPaymentData(paymentDetails);
    console.log(paymentDetails);
  };

  const processFundsPayout = async (obligationId) => {
    console.log(obligationId);
    setProcessLoading(true);
    const { data, error, message } = await fundObligationsPayout(
      user.token,
      obligationId
    );
    if (data) {
      setProcessLoading(false);
      close();
      dispatch(
        showSnackbar({
          type: "success",
          message: "Process Successful",
          autoHideDuration: 2000,
        })
      );
    }
    if (error) {
      dispatch(
        showSnackbar({
          type: "error",
          message: message || "Process Failed",
          autoHideDuration: 2000,
        })
      );
      setProcessLoading(false);
    }
  };

  useEffect(() => {
    setPaymentData(null);
    if (
      states?.includes(data?.drawerData?.state) &&
      data?.selectedData?.column ===
        "closureDetails.fundsMapFundObligationState"
    )
      getPaymentDetailsData();
  }, [data?.drawerData]);
  return (
    <CustomDrawer open={open} title={title} onClose={close}>
      {drawerLoading ? (
        <DrawerLoading />
      ) : (
        <>
          {setPaymentData ? (
            <>
              <DrawerAccordion
                title="Trade Details"
                data={[
                  {
                    key: "Fill ID",
                    value: data?.selectedData?.data?.askFill.fillId,
                  },
                  {
                    key: "Product",
                    value: data?.selectedData?.data?.isin,
                  },
                  {
                    key: "Quantity",
                    value: data?.selectedData?.data?.quantity,
                  },
                  {
                    key: "Price(₹)",
                    value: formatAmount(
                      data?.selectedData?.data?.askFill?.price
                    ),
                  },
                  {
                    key: "Client ID",
                    value: data?.selectedData?.data?.askFill?.userId,
                  },
                  {
                    key: "Order Action ",
                    value: data?.selectedData?.data?.askFill?.action,
                  },
                  {
                    key: "Client Obligation ID",
                    value: data?.selectedData?.data?.askFill?.obligationId,
                  },
                ]}
              />

              <DrawerAccordion
                title="Obligation Details"
                data={
                  data?.drawerData
                    ? [
                        {
                          key: "Fill ID",
                          value: data?.drawerData?.obligationId,
                        },
                        {
                          key: "Amount to be transferred(₹)",
                          value: formatAmount(data?.drawerData?.amount),
                        },
                        {
                          key: "Amount transferred(₹)",
                          value: "",
                        },
                        {
                          key: "Status",
                          value: data?.drawerData?.state,
                        },
                        {
                          key: "Creation Time",
                          value: formatDate(
                            data?.drawerData?.creationTimestamp
                          ),
                        },
                        {
                          key: "Completion Time",
                          value: formatDate(
                            data?.drawerData?.obligationMeetingTime
                          ),
                        },
                        {
                          key: "Closure ID",
                          value: data?.drawerData?.closureId,
                        },
                        {
                          key: "Client’s Bank Account Number",
                          value:
                            data?.drawerData?.targetBankAccountId
                              ?.bankAccountNumber,
                        },
                        {
                          key: "Client’s Bank Account IFSC",
                          value: data?.drawerData?.targetBankAccountId?.ifsc,
                        },
                        {
                          key: "FundsMap Bank Account Number",
                          value:
                            data?.drawerData?.sourceBankAccountId
                              ?.bankAccountNumber,
                        },
                        {
                          key: "FundsMap Bank Account IFSC",
                          value: data?.drawerData?.sourceBankAccountId?.ifsc,
                        },
                        {
                          key: "Type",
                          value: "FundsMap",
                        },
                      ]
                    : []
                }
                message="No Fund Obligation"
              />

              {paymentData ? (
                <DrawerAccordion
                  title="Payment Details"
                  data={[
                    {
                      key: "Payment ID",
                      value: paymentData?.id,
                    },
                    {
                      key: "Payment Gateway",
                      value: paymentData?.payoutSystem,
                    },
                    {
                      key: "PG ID",
                      value: paymentData?.downstreamId,
                    },
                    {
                      key: "Payment Created By User ID",
                      value: paymentData?.processInitiationUserId,
                    },
                    {
                      key: "Payment Creation Time",
                      value: paymentData?.processCreationTime,
                    },
                    {
                      key: "Target Bank IFSC",
                      value: paymentData?.targetBankAccountId.ifsc,
                    },
                    {
                      key: "Target Bank Account No",
                      value: paymentData?.targetBankAccountId.bankAccountNumber,
                    },
                    {
                      key: "Beneficiary Name on Bank Account",
                      value: paymentData?.targetUserNameOnBankAccount,
                    },
                  ]}
                />
              ) : (
                shouldPayoutButtonBeShown && (
                  <Button
                    style={{
                      marginBottom: "20px",
                    }}
                    variant="contained"
                    size="large"
                    fullWidth
                    color="primary"
                    onClick={() =>
                      processFundsPayout(data?.drawerData?.obligationId)
                    }
                    disabled={processLoading}
                  >
                    {processLoading ? (
                      <CircularProgress size={30} padding="20px 0" />
                    ) : (
                      "Make Fund Payout"
                    )}
                  </Button>
                )
              )}
            </>
          ) : (
            "This is empty"
          )}
        </>
      )}
    </CustomDrawer>
  );
};
export const Drawer6 = ({ open, title, close, data, drawerLoading }) => {
  const user = useSelector(selectUser);

  const [processLoading, setProcessLoading] = useState(false);
  const [paymentData, setPaymentData] = useState(null);
  const states = ["CREATED"];

  const getPaymentDetailsData = async () => {
    const { data: paymentDetails } = await getFundObligationsPaymentDetails(
      user.token,
      data?.drawerData?.obligationId
    );
    setPaymentData(paymentDetails);
    console.log(paymentDetails);
  };

  const processFundsPayout = async (obligationId) => {
    console.log(obligationId);
    setProcessLoading(true);
    try {
      const { data } = await shareObligationsPayout(user.token, obligationId);
      console.log(data);
      setPaymentData(false);
    } catch (error) {
      setProcessLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    setPaymentData(null);
    if (
      !states?.includes(data?.drawerData?.state) &&
      data?.selectedData?.column ===
        "closureDetails.fundsMapShareObligationState"
    )
      getPaymentDetailsData();
  }, [data?.drawerData]);
  return (
    <CustomDrawer open={open} title={title} onClose={close}>
      {drawerLoading ? (
        <DrawerLoading />
      ) : (
        <>
          <DrawerAccordion
            title="Trade Details"
            data={[
              {
                key: "Fill ID",
                value: data?.selectedData?.data?.bidFill.fillId,
              },
              {
                key: "Product",
                value: data?.selectedData?.data?.isin,
              },
              {
                key: "Quantity",
                value: data?.selectedData?.data?.quantity,
              },
              {
                key: "Price(₹)",
                value: formatAmount(data?.selectedData?.data?.bidFill?.price),
              },
              {
                key: "Client ID",
                value: data?.selectedData?.data?.bidFill?.userId,
              },
              {
                key: "Order Action ",
                value: data?.selectedData?.data?.bidFill?.action,
              },
              {
                key: "Client Obligation ID",
                value: data?.selectedData?.data?.bidFill?.obligationId,
              },
            ]}
          />

          <DrawerAccordion
            title="FundsMap Share Obligation Details"
            data={
              data?.drawerData
                ? [
                    {
                      key: "Obligation ID",
                      value: data?.drawerData?.obligationId,
                    },
                    {
                      key: "ISIN",
                      value: data?.drawerData?.isin,
                    },
                    {
                      key: "Symbol",
                      value: data?.drawerData?.symbol,
                    },
                    {
                      key: "Expected Shares",
                      value: data?.drawerData?.qty,
                    },
                    {
                      key: "Shares already transferred",
                      value: data?.drawerData?.sharesTransferred,
                    },
                    {
                      key: "Status",
                      value: data?.drawerData?.state,
                    },
                    {
                      key: "Creation Time",
                      value: formatDate(data?.drawerData?.creationTimestamp),
                    },
                    {
                      key: "Completion Time",
                      value: formatDate(
                        data?.drawerData?.obligationMeetingTime
                      ),
                    },
                    {
                      key: "Fill ID",
                      value: data?.drawerData?.fillId,
                    },
                    {
                      key: "Closure ID",
                      value: data?.drawerData?.closureId,
                    },
                    {
                      key: "FundsMap DP ID [Source]",
                      value: data?.drawerData?.sourceDPId,
                    },
                    {
                      key: "FundsMap BO ID [Source]",
                      value: data?.drawerData?.sourceBoId,
                    },
                    {
                      key: "Client’s DP ID [Target]",
                      value: data?.drawerData?.targetDPId,
                    },
                    {
                      key: "Client’s BO ID [Target]",
                      value: data?.drawerData?.targetBoId,
                    },
                    {
                      key: "Type",
                      value: data?.drawerData?.obligationUserType,
                    },
                    {
                      key: "UTR",
                      value: data?.drawerData?.receiptEntryId,
                    },
                  ]
                : []
            }
            message="No Share Obligation"
          />

          {paymentData ? (
            <DrawerAccordion
              title="Payment Details"
              data={[
                {
                  key: "Payment ID",
                  value: paymentData?.paymentId,
                },
                {
                  key: "ISIN",
                  value: paymentData?.isin,
                },
                {
                  key: "Quantity",
                  value: paymentData?.qty,
                },
                {
                  key: "Status",
                  value: paymentData?.status,
                },
                {
                  key: "Reason",
                  value: paymentData?.reason,
                },
                {
                  key: "Share Payout System",
                  value: paymentData?.payoutSystem,
                },
                {
                  key: "Source Depository",
                  value: paymentData?.sourceDpType,
                },
                {
                  key: "Source DP ID",
                  value: paymentData?.sourceDpId,
                },
                {
                  key: "Source BO ID",
                  value: paymentData?.sourceBoId,
                },
                {
                  key: "Target Depository",
                  value: paymentData?.targetDpType,
                },
                {
                  key: "Target DP ID",
                  value: paymentData?.targetDpId,
                },
                {
                  key: "Target BO ID",
                  value: paymentData?.targetBoId,
                },
                {
                  key: "Creation Time",
                  value: formatDate(paymentData?.processCreationTime),
                },
                {
                  key: "Completion Time",
                  value: formatDate(paymentData?.processCompletionTime),
                },
                //! shareObligation ye nhi hai
                {
                  key: "DP Request Transaction Reference",
                  value: paymentData?.shareObligation?.receiptEntryId,
                },
                {
                  key: "Batch ID",
                  value: paymentData?.batchId,
                },
              ]}
            />
          ) : (
            true
          )}
        </>
      )}
    </CustomDrawer>
  );
};
