import React from "react";

// icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";

const DrawerAccordion = ({
  title,
  data,
  isTable,
  table = null,
  message = "These is no Data",
  messageType = "error",
}) => {
  return (
    <Accordion
      defaultExpanded
      style={{
        width: "100%",
        border: "1px solid #ddd",
        borderRadius: "10px",
        marginBottom: "20px",
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography fontWeight="bold">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {isTable ? (
          <>
            {table?.rows?.length > 0 ? (
              <Table>
                <TableHead>
                  <TableRow>
                    {table?.columns?.map((column) => (
                      <TableCell key={column}>{column}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {table?.rows?.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      {row?.map((cell) => (
                        <TableCell key={cell}>{cell}</TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <Alert variant="standard" severity={messageType}>
                {message}
              </Alert>
            )}
          </>
        ) : (
          <>
            {data?.length > 0 ? (
              <List>
                {data?.map((i, index) => (
                  <ListItem
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderBottom:
                        data?.length - 1 !== index ? "1px solid #ddd" : "",
                      width: "100%",
                    }}
                  >
                    <Typography fontWeight="bold">{i?.key}</Typography>
                    <Tooltip title={i?.value}>
                      <Typography
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "60%",
                          textAlign: "right",
                        }}
                      >
                        {i?.value || "--"}
                      </Typography>
                    </Tooltip>
                  </ListItem>
                ))}
              </List>
            ) : (
              <Alert variant="standard" severity={messageType}>
                {message}
              </Alert>
            )}
          </>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default DrawerAccordion;
