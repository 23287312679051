import MaterialReactTable from "material-react-table";
import {
  Box,
  Button,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import MenuButton from "./MenuButton";

const OrdersTable = ({
  selectData,
  selectedData,
  // openTransactionDialog,
  tableData,
}) => {
  return (
    <div>
      <MaterialReactTable
        enableColumnResizing
        columns={tableData?.columns}
        data={tableData?.data || []}
        enableGrouping
        enableStickyHeader
        enableStickyFooter
        muiToolbarAlertBannerChipProps={{ color: "primary" }}
        muiTableContainerProps={{ sx: { maxHeight: 700 } }}
        state={{
          isLoading: tableData.isLoading,
        }}
        positionToolbarAlertBanner="top"
        initialState={{
          density: "compact",
          expanded: true,
          grouping: ["company"], // this is giving error
        }}
        enableRowActions
        displayColumnDefOptions={{ "mrt-row-actions": { size: 280 } }}
        muiTableBodyRowProps={({ row }) => ({
          selected:
            selectedData?.orderDetail?.orderRequestId ===
            row.original?.orderDetail?.orderRequestId,
        })}
        muiTableBodyCellProps={({ cell, row }) => ({
          onClick: () => {
            if (!cell.id?.includes("row-actions")) selectData(row.original);
          },
        })}
        renderRowActions={({ row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <MenuButton
              title="Modify"
              menu={[
                <a
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                  href={(() => {
                    if (process.env.REACT_APP_PROFILE === "dev")
                      return `http://localhost:3000/order-modification?orderId=${row?.original?.orderDetail?.orderRequestId}&type=modify`;
                    else if (process.env.REACT_APP_PROFILE === "staging")
                      return `https://sahil-working--app-fundsmap-prod.netlify.app/order-modification?orderId=${row?.original?.orderDetail?.orderRequestId}&type=modify`;
                    else if (process.env.REACT_APP_PROFILE === "prod")
                      return `https://app.fundsmap.com/order-modification?orderId=${row?.original?.orderDetail?.orderRequestId}&type=modify`;
                    else
                      return `https://app.fundsmap.com/order-modification?orderId=${row?.original?.orderDetail?.orderRequestId}&type=modify`;
                  })()}
                  target="_blank"
                >
                  <ListItemIcon>
                    <OpenInNewIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Open</ListItemText>
                </a>,
                <Tooltip title="Copy the link">
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                    onClick={() => {
                      navigator.clipboard.writeText(
                        (() => {
                          if (process.env.REACT_APP_PROFILE === "dev")
                            return `http://localhost:3000/order-modification?orderId=${row?.original?.orderDetail?.orderRequestId}&type=modify`;
                          else if (process.env.REACT_APP_PROFILE === "staging")
                            return `https://sahil-working--app-fundsmap-prod.netlify.app/order-modification?orderId=${row?.original?.orderDetail?.orderRequestId}&type=modify`;
                          else if (process.env.REACT_APP_PROFILE === "prod")
                            return `https://app.fundsmap.com/order-modification?orderId=${row?.original?.orderDetail?.orderRequestId}&type=modify`;
                          else
                            return `https://app.fundsmap.com/order-modification?orderId=${row?.original?.orderDetail?.orderRequestId}&type=modify`;
                        })()
                      );
                    }}
                  >
                    <ListItemIcon>
                      <ContentCopyIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Copy</ListItemText>
                  </Box>
                </Tooltip>,
              ]}
              disabled={
                ![
                  "AWAITING_CONFIRMATION",
                  "ORDER_CONFIRMED",
                  "PARTIALLY_FILLED",
                ]?.includes(row?.original?.orderDetail?.consolidatedOrderStatus)
              }
            />
            <MenuButton
              title="Details"
              menu={[
                <a
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                  href={(() => {
                    if (process.env.REACT_APP_PROFILE === "dev")
                      return `http://localhost:3000/order/${row?.original?.orderDetail?.orderRequestId}?v=${row?.original?.orderDetail?.version}`;
                    else if (process.env.REACT_APP_PROFILE === "staging")
                      return `https://sahil-working--app-fundsmap-prod.netlify.app/order/${row?.original?.orderDetail?.orderRequestId}?v=${row?.original?.orderDetail?.version}`;
                    else if (process.env.REACT_APP_PROFILE === "prod")
                      return `https://app.fundsmap.com/order/${row?.original?.orderDetail?.orderRequestId}?v=${row?.original?.orderDetail?.version}`;
                    else
                      return `https://app.fundsmap.com/order/${row?.original?.orderDetail?.orderRequestId}?v=${row?.original?.orderDetail?.version}`;
                  })()}
                  target="_blank"
                >
                  <ListItemIcon>
                    <OpenInNewIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Open</ListItemText>
                </a>,
                <Tooltip title="Copy the link">
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                    onClick={() => {
                      navigator.clipboard.writeText(
                        (() => {
                          if (process.env.REACT_APP_PROFILE === "dev")
                            return `http://localhost:3000/order/${row?.original?.orderDetail?.orderRequestId}?v=${row?.original?.orderDetail?.version}`;
                          else if (process.env.REACT_APP_PROFILE === "staging")
                            return `https://sahil-working--app-fundsmap-prod.netlify.app/order/${row?.original?.orderDetail?.orderRequestId}?v=${row?.original?.orderDetail?.version}`;
                          else if (process.env.REACT_APP_PROFILE === "prod")
                            return `https://app.fundsmap.com/order/${row?.original?.orderDetail?.orderRequestId}?v=${row?.original?.orderDetail?.version}`;
                          else
                            return `https://app.fundsmap.com/order/${row?.original?.orderDetail?.orderRequestId}?v=${row?.original?.orderDetail?.version}`;
                        })()
                      );
                    }}
                  >
                    <ListItemIcon>
                      <ContentCopyIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Copy</ListItemText>
                  </Box>
                </Tooltip>,
              ]}
            />
          </Box>
        )}
        rowCount={tableData.rowCount}
        // onPaginationChange={tableData.setPagination}
        // muiTableBodyRowProps={({ row }) => ({
        //   selected: row?.original?.orderDetail?.orderRequestId
        //     ? row?.original?.orderDetail?.orderRequestId === selectedData?.orderRequestId
        //     : false,
        //   onClick: () => {
        //     selectData(row.original);
        //   },
        // })}
      />
    </div>
  );
};

export default OrdersTable;
