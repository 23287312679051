import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { loginOtp, registerEmail } from "../axios/register";
import { validEmail } from "../utils";
import decode from "jwt-decode";
import { profileDetails } from "../axios/profile";
import { useDispatch } from "react-redux";
import { loginUser } from "../features/user/userSlice";
import { generateOTP } from "../axios/otp";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  TextField,
  CircularProgress,
  Snackbar,
  Alert,
  IconButton,
} from "@mui/material";
import { loginWithEmailandOTP } from "../axios/login";

// icons
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const Login = () => {
  // constants and variables
  const location = useLocation();
  const searchQuery = new URLSearchParams(location.search);
  const referredBy = searchQuery.get("ReferredBy");
  const navigate = useNavigate();
  const redirectPath = searchQuery.get("redirectPath");
  const partnerName = searchQuery.get("partnerName");
  const dispatch = useDispatch();

  // states
  const [isLoading, setIsLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(true);
  const [snackbarData, setSnackbarData] = useState({
    open: false,
    variant: "success",
    anchorOrigin: {
      horizontal: "right",
      vertical: "top",
    },
    autoHideDuration: 3000,
    message: "",
  });
  const [showOtp, setShowOtp] = useState(false);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");

  // functions
  const submitEmail = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const { data, status, error } = await generateOTP(email);
    console.log(data);
    if (status === 200) setShowOtp(true);
    if (error) {
      openSnackbar({
        open: true,
        message: error.message,
        variant: "error",
      });
      console.log(error);
    }
    setIsLoading(false);
  };
  const submitOTP = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const { data, error, message } = await loginWithEmailandOTP(email, otp);
    if (data) {
      localStorage.setItem("token", data?.token);
      localStorage.setItem("user", JSON.stringify(data?.user));
      const computedUser = { ...data.user, token: data?.token };
      dispatch(loginUser(computedUser));
    }
    if (error) {
      openSnackbar({
        open: true,
        message: message,
        variant: "error",
      });
      console.log(message);
    }
    setIsLoading(false);
  };
  const openSnackbar = ({
    open = true,
    variant = "success",
    anchorOrigin = {
      horizontal: "right",
      vertical: "top",
    },
    autoHideDuration = 3000,
    message = "",
  }) => {
    setSnackbarData({
      ...snackbarData,
      open,
      variant,
      anchorOrigin,
      autoHideDuration,
      message,
    });
  };
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      width="100vw"
    >
      <Snackbar
        anchorOrigin={snackbarData.anchorOrigin}
        open={snackbarData.open}
        autoHideDuration={snackbarData.autoHideDuration}
        onClose={() =>
          openSnackbar({
            open: false,
          })
        }
      >
        <Alert severity={snackbarData.variant}>{snackbarData.message}</Alert>
      </Snackbar>

      {showOtp ? (
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            alignItems: "start",
            flexDirection: "column",
            width: "100%",
            padding: "0 20px",
            maxWidth: "600px",
          }}
        >
          <IconButton
            onClick={() => {
              setShowOtp(false);
              setOtp("");
            }}
            sx={{
              bgcolor: "#333",
              color: "white",
              marginBottom: 3,
            }}
          >
            <ArrowBackIcon fontSize="small" />
          </IconButton>
          <form
            onSubmit={submitOTP}
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "10px",
              alignItems: "center",
              width: "100%",
            }}
          >
            <FormControl fullWidth={true}>
              <TextField
                label="OTP"
                placeholder="Enter your otp"
                autoFocus
                type="number"
                value={otp}
                min="0"
                onChange={(e) => setOtp(e.target.value.slice(0, 6))}
              />
            </FormControl>

            <Button
              type="submit"
              color="primary"
              variant="contained"
              size="large"
              disabled={otp.length < 6 || isLoading}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {isLoading ? <CircularProgress size={25} /> : "Login"}
            </Button>
          </form>
        </Box>
      ) : (
        <form
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
            alignItems: "center",
            width: "100%",
            padding: "0 20px",
            maxWidth: "600px",
          }}
          onSubmit={submitEmail}
        >
          <FormControl fullWidth={true}>
            <TextField
              label="Email"
              autoFocus
              size="medium"
              variant="outlined"
              type="email"
              value={email}
              disabled={isLoading}
              placeholder="Enter your email"
              fullWidth={true}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            size="large"
            disabled={!validEmail(email) || email.length === 0 || isLoading}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {isLoading ? <CircularProgress size={25} /> : "Get OTP"}
          </Button>
        </form>
      )}
    </Box>
  );
};

export default Login;
