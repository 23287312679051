export const statusColors = {
  AWAITING_CONFIRMATION: "#53524c",
  SIGNING: "#f2bc19",
  SIGNATURE_EXPIRED: "#239de5",
  ORDER_CONFIRMED: "#1ed429",
  ORDER_EXPIRED: "#233ce5",
  PARTIALLY_FILLED: "#147319",
  FULLY_FILLED: "#147319",
  CANCELLED: "#ee3d3f",
  INTEREST_VALIDATED: "#c80709",
  IN_ERROR: "#c80709",
  UNOPENED: "#53524c",
};
