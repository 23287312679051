import { json2csv } from "json-2-csv";

export const validEmail = (email) =>
  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);

export const formatDate = (epoc) => {
  if (epoc != 0) {
    return new Date(epoc * 1000).toLocaleDateString("en-IN", {
      hour12: true,
      hour: "2-digit",
      minute: "2-digit",
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  } else {
    return "NA";
  }
};

export const formatAmount = (amount) => amount?.toLocaleString("en-IN");

export const isObjectEmpty = (obj) => {
  return obj ? Object.keys(obj).length > 0 : false;
};

export const jsonToCsvDownload = (data, fileName = "Download") => {
  json2csv(data, (err, csv) => {
    const file = new Blob([csv], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(file);
    link.download = `${fileName}.csv`;
    link.click();
  });
};
