import { api } from "..";

export const getPendingAction = async (token) => {
  try {
    const { data } = await api({
      url: `/api/v1/custodian/actions/pending`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return {
      data,
    };
  } catch (error) {
    return {
      error,
    };
  }
};
