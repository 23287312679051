import { breadcrumbsClasses } from "@mui/material";
import axios from "axios";

const api = axios.create({
  baseURL: getBaseUrl(process.env.REACT_APP_PROFILE)
});


function getBaseUrl (profile) {
 switch(profile){
   case "dev": {
     return process.env.REACT_APP_DEV_LINK;
   }
   case "staging": {
    return process.env.REACT_APP_STAGING_LINK;
  }
  case "prod": {
    return process.env.REACT_APP_PROD_LINK;
  } default:{
    return process.env.REACT_APP_DEV_LINK;
  }
 }
}
export { api };
