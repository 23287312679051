import { api } from ".";

export const registerEmail = async (email, referredBy, partnerName) => {
  try {
    const { data } = await api({
      url: referredBy
        ? `api/v1/users/continue-with-email?referredBy=${referredBy}`
        : "api/v1/users/continue-with-email",
      method: "POST",
      headers: {
        Authorization: null,
      },
      data: {
        email: email,
        partnerName: partnerName,
      },
    });
    console.log(data);
    return {
      status: "Success",
      data,
    };
  } catch (error) {
    console.log(error);
    return {
      status: "Error",
      error,
    };
  }
};

export const loginOtp = async (email, otp) => {
  try {
    const { data } = await api({
      url: `api/v1/users/login?type=EMAIL&email=${email}&otp=${otp}&mode=OTP`,
      method: "POST",
      headers: {
        Authorization: null,
      },
    });
    return {
      status: "Success",
      data,
    };
  } catch (error) {
    console.log(error);
    return {
      status: "Error",
      error,
    };
  }
};
