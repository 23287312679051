import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  show: false,
  type: "info",
  message: null,
  autoHideDuration: 3000,
};

const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    showSnackbar: (state, action) => {
      state = {
        show: true,
        ...action.payload,
      };
      return state;
    },
    closeSnackbar: (state) => {
      state = initialState;
      return state;
    },
  },
});

export { snackbarSlice };

export const { closeSnackbar, showSnackbar } = snackbarSlice.actions;

export const selectSnackbar = (state) => state.snackbar;

export default snackbarSlice.reducer;
