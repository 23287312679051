import { useEffect, useState } from "react";

// mui components
import { Box } from "@mui/system";
import { Avatar, Chip, Tab, Tabs, Tooltip, Typography } from "@mui/material";

// redux
import { useSelector } from "react-redux";
import { selectUser } from "../features/user/userSlice";

// utils
import { formatAmount, formatDate } from "../utils";

// constants
import { statusColors } from "../constants/statusColors";

// api calls
import { getOrders } from "../axios/orders/getOrders";

// custom components
import Main from "../components/layouts/Main";
import CustomDrawer from "../components/layouts/CustomDrawer";
import OrdersTable from "../components/layouts/OrdersTable";
import DrawerAccordion from "../components/layouts/DrawerAccordion";

const Orders = () => {
  const [selectedData, setSelectedData] = useState(null);
  const [value, setValue] = useState(0);

  const [tableData, setTableData] = useState({
    isLoading: true,
    data: [],
    columns: [
      {
        accessorKey: "company",
        header: "Company",
        Cell: ({ cell, row }) => (
          <Tooltip title={cell.getValue()}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <Avatar
                src={row.original?.companyDetail?.logo}
                variant="square"
                sx={{ width: 40, height: 40 }}
              />
              <Typography
                sx={{
                  fontWeight: 700,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {cell.getValue()}
              </Typography>
            </Box>
          </Tooltip>
        ),
      },
      {
        accessorKey: "orderDetail.action",
        header: "Side",
        Cell: ({ cell }) => (
          <Chip
            sx={{
              bgcolor: cell.getValue() === "BID" ? "#1E95E1" : "#E16A1E",
            }}
            label={cell.getValue()}
            color={"primary"}
            size="small"
          />
        ),
      },
      {
        accessorKey: "orderDetail.qty",
        header: "Quantity",
        aggregationFn: "sum",
        AggregatedCell: ({ cell, table }) => (
          <>
            Total Quantity:{" "}
            <Box
              sx={{ color: "info.main", display: "inline", fontWeight: "bold" }}
            >
              {cell.getValue()}
            </Box>
          </>
        ),
        enableGrouping: false,
      },
      {
        accessorKey: "orderDetail.price",
        header: "Price (₹)",
        Cell: ({ cell }) =>
          cell.getValue() ? formatAmount(cell.getValue()) : "--",
        enableGrouping: false,
      },
      {
        accessorKey: "orderDetail.filledQty",
        header: "Pending Quantity",
        Cell: ({ row }) =>
          row.original?.orderDetail?.qty - row.original?.orderDetail?.filledQty,
        enableGrouping: false,
      },
      {
        accessorKey: "orderDetail.expiryTime",
        header: "Expiry Time",
        Cell: ({ cell }) =>
          cell.getValue() ? formatDate(cell.getValue()) : "--",
        enableGrouping: false,
      },
      {
        accessorKey: "orderDetail.consolidatedOrderStatus",
        header: "Status",
        Cell: ({ cell }) => (
          <Tooltip title={cell.getValue()}>
            <Chip
              sx={{
                bgcolor: statusColors[cell.getValue()],
                color: "#fff",
                textAlign: "center",
                padding: "0 10px",
              }}
              label={cell.getValue()}
              color="info"
              size="small"
            />
          </Tooltip>
        ),
      },
      {
        accessorKey: "placedByContactInformation.fullName",
        header: "Placed By",
      },
      // {
      //   accessorKey: "orderDetail.orderRequestId",
      //   header: "Order ID",
      // },
    ],
  });
  const user = useSelector(selectUser);

  const selectData = (data) => setSelectedData(data);

  const orderTypes = ["OPEN", "COMPLETED"];

  const getData = async () => {
    selectData(null);
    const { data } = await getOrders(user.token, orderTypes[value]);
    console.clear();
    console.log(data);
    setTableData({
      ...tableData,
      isLoading: false,
      data: data?.map((order) => ({
        company: order?.companyDetail?.aka,
        ...order,
      })),
    });
  };

  useEffect(() => {
    getData();
    setTableData({
      ...tableData,
      data: [],
      isLoading: true,
    });
  }, [value]);

  const closeDrawer = () => setSelectedData(null);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <>
      <Box
        sx={{
          padding: {
            xs: 1,
            sm: 2,
          },
        }}
      >
        <Box>
          <Tabs
            value={value}
            onChange={(event, newValue) => setValue(newValue)}
            aria-label="basic tabs example"
          >
            {orderTypes?.map((type) => (
              <Tab key={type} label={type} {...a11yProps(0)} />
            ))}
          </Tabs>
        </Box>
        <Main open={selectedData ? true : false}>
          <OrdersTable
            tableData={tableData}
            selectedData={selectedData}
            selectData={selectData}
          />
        </Main>
        <CustomDrawer
          title="Order Details"
          onClose={closeDrawer}
          open={selectedData ? true : false}
        >
          <DrawerAccordion
            title="Order Details"
            data={[
              {
                key: "Side",
                value: selectedData?.orderDetail.action,
              },
              {
                key: "Quantity",
                value: selectedData?.orderDetail.qty,
              },
              {
                key: "Price",
                value: formatAmount(selectedData?.orderDetail.price),
              },
              {
                key: "Minimum Quantity",
                value: selectedData?.orderDetail?.minimumFulfillmentQty,
              },
              {
                key: "Pending Quantity",
                value:
                  selectedData?.orderDetail?.qty -
                  selectedData?.orderDetail?.filledQty,
              },
              {
                key: "Product",
                value: selectedData?.companyDetail?.aka,
              },
              {
                key: "Order Status",
                value: selectedData?.orderDetail?.consolidatedOrderStatus,
              },
              {
                key: "Fill Status",
                value: selectedData?.orderDetail?.fillStatus,
              },
              {
                key: "Order ID",
                value: selectedData?.orderDetail?.orderRequestId,
              },
              {
                key: "Order Version",
                value: selectedData?.orderDetail?.version,
              },
              {
                key: "Placement Type",
                value: selectedData?.orderDetail?.placementType,
              },
              {
                key: "Confirmation Time",
                value: formatDate(
                  selectedData?.orderDetail?.requestReceivedTimestamp
                ),
              },
              {
                key: "Expires At",
                value: formatDate(selectedData?.orderDetail?.expiryTimestamp),
              },
              {
                key: "Placed For Name",
                value: selectedData?.placedForContactInformation.fullName,
              },
              {
                key: "Placed For ID",
                value: selectedData?.placedForContactInformation.userId,
              },
              {
                key: "Placed By Name",
                value: selectedData?.placedByContactInformation.fullName,
              },
              {
                key: "Placed By ID",
                value: selectedData?.placedByContactInformation.userId,
              },
            ]}
          />
        </CustomDrawer>
      </Box>
    </>
  );
};

export default Orders;
