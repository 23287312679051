import { api } from "..";

export const processShareObligationsPayout = async (
  token,
  disReceiptNo,
  shareObligationIds
) => {
  try {
    const { data } = await api({
      method: "post",
      url: `/api/v2/admin/shares/shareObligations/payout`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        disReceiptNo,
        startNo: 1,
        shareObligationIds,
      },
    });
    return {
      data,
    };
  } catch (error) {
    return {
      error,
    };
  }
};
