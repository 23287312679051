import { api } from ".";

export const generateOTP = async (email) => {
  try {
    const { data, status } = await api({
      url: `api/v1/users/login/otp?type=EMAIL&email=${email}`,
      method: "post",
    });

    return { data, status };
  } catch (error) {
    return { error };
  }
};
