import { api } from "..";

export const processWithdraws = async (token, ids) => {
  try {
    const { data, status } = await api({
      url: `/api/v2/admin/funds/withdraws/process/`,
      method: "post",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        withdrawIds: ids,
      },
    });
    return {
      data,
      status,
    };
  } catch (error) {
    return {
      error,
    };
  }
};
