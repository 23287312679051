import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { forwardRef } from "react";
import { useDispatch } from "react-redux";
import { closeSnackbar, selectSnackbar } from "../features/snackbarSlice";
import { useSelector } from "react-redux";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const GlobalSnackbar = () => {
  const snackbar = useSelector(selectSnackbar);
  const dispatch = useDispatch();
  const handleClose = () => dispatch(closeSnackbar());
  return (
    <Snackbar
      open={snackbar?.show}
      autoHideDuration={snackbar?.autoHideDuration || 6000}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        severity={snackbar?.type}
        sx={{ width: "100%" }}
      >
        {snackbar?.message}
      </Alert>
    </Snackbar>
  );
};

export default GlobalSnackbar;
