import { api } from "..";

export const processEasiestSharePayout = async (
  token,
  //   disReceiptNo,
  shareObligationIds
) => {
  try {
    const { data } = await api({
      method: "post",
      url: `/api/v2/admin/shares/shareObligations/payouts/easiest`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        shareObligationIds,
      },
    });
    return {
      data,
    };
  } catch (error) {
    return {
      error,
    };
  }
};
