import { api } from "..";

export const getClosure = async (token, closureId) => {
  try {
    const { data } = await api({
      url: `/api/v1/admin/closures/${closureId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return {
      data,
    };
  } catch (error) {
    return {
      error,
    };
  }
};
