import { Drawer, IconButton, Typography } from "@mui/material";
import React from "react";

// icons
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/system";

const CustomDrawer = ({ open, onClose, title, children }) => {
  return (
    <Drawer
      open={open}
      onClose={onClose}
      variant="persistent"
      anchor="right"
      sx={{
        width: {
          xs: "100%",
          sm: 500,
        },
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: {
            xs: "100%",
            sm: 500,
          },
          boxSizing: "border-box",
        },
      }}
    >
      <Box
        bgcolor="#333"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "12px",
        }}
      >
        <Typography
          variant="h6"
          component="div"
          sx={{
            flexGrow: 1,
            color: "#fff",
            marginLeft: "10px",
          }}
        >
          {title}
        </Typography>
        <IconButton
          onClick={onClose}
          style={{
            background: "#222",
          }}
        >
          <CloseIcon
            color="#fff"
            style={{
              color: "#fff",
            }}
          />
        </IconButton>
      </Box>
      <Box padding={2}>{children}</Box>
    </Drawer>
  );
};

export default CustomDrawer;
