import React, { useEffect, useState } from "react";

// material icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PaidIcon from "@mui/icons-material/Paid";
import { Download as DownloadIcon } from "@mui/icons-material";

// material ui components
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  IconButton,
  Divider,
  Tooltip,
  Typography,
  Stack,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import { Box } from "@mui/system";

// redux
import { selectUser } from "../features/user/userSlice";
import { useSelector } from "react-redux";

// api calling functions
import { getFundsObligations } from "../axios/funds/getObligations";
import { getClosure } from "../axios/transaction/getClosure";
import { getShareObligation } from "../axios/transaction/getSharesObligation";
import { getTransactions } from "../axios/transaction/getTransactions";
import { getPendingAction } from "../axios/transaction/getPendingActions";
import { processShareObligationsPayout } from "../axios/transaction/processshareObligationPayout";

// custom components
import Main from "../components/layouts/Main";
import TransactionTable from "../components/TransactionTable";

// utilities
import { jsonToCsvDownload } from "../utils";

// drawers
import {
  Drawer1,
  Drawer2,
  Drawer3,
  Drawer4,
  Drawer5,
  Drawer6,
} from "../components/Drawers";
import TabPanel from "../components/layouts/TabPanel";
import { processEasiestSharePayout } from "../axios/transaction/processEasiestSharePayout";
import { api } from "../axios";

const Transaction = () => {
  const user = useSelector(selectUser);
  const [selectedData, setSelectedData] = useState({
    data: null,
    column: null,
  });

  const [payoutOptions, setPayoutOptions] = useState(0);
  const [pendingAction, setPendingAction] = useState(null);
  const [drawerData, setDrawerData] = useState(null);
  const [drawerLoading, setDrawerLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTableRows, setDialogTableRows] = useState([]);
  const [disID, setDisID] = useState("");
  const [dialogLoading, setDialogLoading] = useState(false);
  const [
    processShareObligationsPayoutData,
    setProcessShareObligationsPayoutData,
  ] = useState(null);
  const [value, setValue] = useState(0);

  //store pagination state in your own state
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 15, //customize the default page size
  });

  useEffect(() => {
    getTransactionData();
  }, [pagination.pageIndex, pagination.pageSize]);

  const [tableData, setTableData] = useState({
    isLoading: true,
    data: [],
    columns: [
      {
        accessorKey: "transactionId",
        header: "Transaction ID",
        accessorFn: (row) =>
          row.askFill.fillId + row.bidFill.fillId,
      },
      {
        accessorKey: "quantity",
        header: "Quantity",
      },
      {
        accessorKey: "isin",
        header: "Product",
      },
      {
        accessorKey: "symbol",
        header: "Symbol",
      },
      {
        accessorKey: "askFill.price",
        header: "Ask Price (₹)",
      },
      {
        accessorKey: "bidFill.price",
        header: "Bid Price (₹)",
      },
      {
        accessorKey: "status",
        header: "Transaction Status",
      },
      {
        accessorKey: "bidFill.status",
        header: "Fund Obligation Status",
      },
      {
        accessorKey: "askFill.status",
        header: "Share Obligation Status",
      },
      {
        //accessorFn used to access nested data, though you could just use dot notation in an accessorKey
        //need to write this because accessed property might not exist as closureDetails can be null
        accessorFn: (row) =>
          row.closureDetails ? row.closureDetails?.closureType : "NA",
        id: "closureDetails.closureType",
        header: "Closure Type",
        Cell: ({ cell }) => (cell.getValue() ? cell.getValue() : "--"),
      },
      {
        //accessorFn used to access nested data, though you could just use dot notation in an accessorKey
        //need to write this because accessed property might not exist as closureDetails can be null
        accessorFn: (row) =>
          row.closureDetails
            ? row.closureDetails?.fundsMapFundObligationState
            : "NA",
        id: "closureDetails.fundsMapFundObligationState",
        header: "Fund Payout Status",
        Cell: ({ cell }) => (cell.getValue() ? cell.getValue() : "--"),
      },
      {
        //accessorFn used to access nested data, though you could just use dot notation in an accessorKey
        //need to write this because accessed property might not exist as closureDetails can be null
        accessorFn: (row) =>
          row.closureDetails
            ? row.closureDetails?.fundsMapShareObligationState
            : "NA",
        id: "closureDetails.fundsMapShareObligationState",
        header: "Share Payout Status",
        Cell: ({ cell }) => (cell.getValue() ? cell.getValue() : "--"),
      },
    ],
    setPagination: setPagination, //hoist pagination state to your state when it changes internally
    pagination: pagination,
  });

  const selectData = (data) => {
    setSelectedData(data);
    setDrawerLoading(true);
  };

  const transactionStatus = ["INPROCESS", "COMPLETE"];

  const getTransactionData = async () => {
    const { data, error } = await getTransactions(
      user.token,
      transactionStatus[value],
      pagination.pageIndex,
      pagination.pageSize
    );
    setTableData({
      ...tableData,
      isLoading: true,
    });
    if (data) {
      setTableData({
        ...tableData,
        data: data?.content,
        isLoading: false,
        rowCount: data?.totalElements,
        pagination: pagination,
      });
    }
    if (error) {
      console.log(error);
    }
  };

  const drawerOpenCondition = {
    drawer1: [
      "transactionId",
      "quantity",
      "isin",
      "symbol",
      "askFill.price",
      "bidFill.price",
      "status",
    ].includes(selectedData?.column)
      ? true
      : false,
    drawer2: ["bidFill.status"].includes(selectedData?.column) ? true : false,
    drawer3: ["askFill.status"].includes(selectedData?.column) ? true : false,
    drawer4: ["closureDetails.closureType"].includes(selectedData?.column)
      ? true
      : false,
    drawer5: ["closureDetails.fundsMapFundObligationState"].includes(
      selectedData?.column
    )
      ? true
      : false,
    drawer6: ["closureDetails.fundsMapShareObligationState"].includes(
      selectedData?.column
    )
      ? true
      : false,
  };

  const getFundsObligationData = async () => {
    setDrawerData(null);
    const { data } = await getFundsObligations(
      user.token,
      selectedData.data?.bidFill.obligationId
    );
    setDrawerData(data);
    setDrawerLoading(false);
  };
  const getShareObligationData = async () => {
    setDrawerData(null);
    const { data } = await getShareObligation(
      user.token,
      selectedData.data?.askFill.obligationId
    );
    console.log(data);
    setDrawerData(data);
    setDrawerLoading(false);
  };
  const getClosureData = async () => {
    setDrawerData(null);
    const { data } = await getClosure(
      user.token,
      selectedData?.data?.closureDetails?.closureId
    );
    console.log("setting closure drawer", data);
    setDrawerData(data);
    setDrawerLoading(false);
  };
  const getFundsMapData = async () => {
    setDrawerData(null);

    if (selectedData?.data?.closureDetails?.fundsMapFundObligationPresent) {
      const { data } = await getFundsObligations(
        user.token,
        selectedData?.data?.closureDetails?.fundsMapFundObligationId
      );
      setDrawerData(data);
      setDrawerLoading(false);
    } else {
      setDrawerData(null);
      setDrawerLoading(false);
    }
    // console.log(selectedData?.data?.closureDetails);
  };

  const getFundsMapShareObligationData = async () => {
    setDrawerData(null);

    if (selectedData?.data?.closureDetails?.fundsMapShareObligationPresent) {
      const { data } = await getShareObligation(
        user.token,
        selectedData?.data?.closureDetails?.fundsMapShareObligationId
      );
      console.log(data);
      setDrawerData(data);
      setDrawerLoading(false);

      // console.log(
      //   selectedData?.data?.closureDetails?.fundsMapShareObligationId
      // );
    } else {
      setDrawerData(null);
      setDrawerLoading(false);
    }
  };
  useEffect(() => {
    setPagination({ pageIndex: 0, pageSize: 15 });
    getTransactionData();
    setSelectedData(null); //to ensure the drawer is closed if open
  }, [value]);

  const openTransactionDialog = (rows) => {
    setOpenDialog(true);
    console.log(rows);
    setDialogTableRows(rows);
  };

  const processTransaction = async () => {
    setDialogLoading(true);

    try {
      const { data } = await processShareObligationsPayout(
        user?.token,
        disID,
        dialogTableRows?.map(
          (row) => row?.closureDetails?.fundsMapShareObligationId
        )
      );
      console.log(data);
      setProcessShareObligationsPayoutData(data);
      setDialogLoading(false);
    } catch (error) {
      console.log(error);
      setDialogLoading(false);
    }
  };

  useEffect(() => {
    if (drawerOpenCondition.drawer2) getFundsObligationData();
    else if (drawerOpenCondition.drawer3) getShareObligationData();
    else if (drawerOpenCondition.drawer4) getClosureData();
    else if (drawerOpenCondition.drawer5) getFundsMapData();
    else if (drawerOpenCondition.drawer6) getFundsMapShareObligationData();
  }, [selectedData]);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const getPendingActionData = async () => {
    const { data } = await getPendingAction(user.token);
    setPendingAction(data);
    console.log(Main);
  };

  const easiestSharePayoutData = async () => {
    setDialogLoading(true);

    try {
      const { data } = await processEasiestSharePayout(
        user?.token,
        dialogTableRows?.map(
          (row) => row?.closureDetails?.fundsMapShareObligationId
        )
      );
      console.log(data);
      setProcessShareObligationsPayoutData(data);
      setDialogLoading(false);
    } catch (error) {
      console.log(error);
      setDialogLoading(false);
    }
  };

  useEffect(() => {
    getPendingActionData();
  }, []);

  return (
    <>
      <Box
        sx={{
          padding: {
            xs: 1,
            sm: 2,
          },
        }}
      >
        <Main
          open={
            drawerOpenCondition.drawer1 ||
            drawerOpenCondition.drawer2 ||
            drawerOpenCondition.drawer3 ||
            drawerOpenCondition.drawer4 ||
            drawerOpenCondition.drawer5 ||
            drawerOpenCondition.drawer6
          }
          sx={{
            display: "flex",
            alignItem: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            style={{
              display: "flex",
              alignItem: "center",
              justifyContent: "space-between",
              width: "100%",
              oveyflowY: "auto",
            }}
          >
            <Tabs
              value={value}
              onChange={(event, newValue) => setValue(newValue)}
              aria-label="basic tabs example"
            >
              <Tab label="Processing" {...a11yProps(0)} />
              <Tab label="Completed" {...a11yProps(1)} />
            </Tabs>
            <Stack
              direction="row"
              spacing={2}
              sx={{
                fontSize: "15px",
              }}
              divider={<Divider orientation="vertical" flexItem />}
            >
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <b>{pendingAction?.remainingSharePayouts?.count}</b> Pending
                share payouts
              </Box>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <b>{pendingAction?.remainingFundPayouts?.count}</b> Pending fund
                payouts
              </Box>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <b>{pendingAction?.remainingNotificationMails?.count}</b>{" "}
                Pending mails to be sent
              </Box>
            </Stack>
          </Box>
        </Main>
        <Main
          open={
            drawerOpenCondition.drawer1 ||
            drawerOpenCondition.drawer2 ||
            drawerOpenCondition.drawer3 ||
            drawerOpenCondition.drawer4 ||
            drawerOpenCondition.drawer5 ||
            drawerOpenCondition.drawer6
          }
        >
          <TransactionTable
            selectData={selectData}
            tableData={tableData}
            selectedData={selectedData}
            openTransactionDialog={openTransactionDialog}
          />
        </Main>
        {/* drawer 1 */}
        <Drawer1
          open={drawerOpenCondition.drawer1}
          title="Transaction Details"
          drawerLoading={drawerLoading}
          close={() =>
            selectData({
              data: null,
              column: null,
            })
          }
          data={selectedData}
        />
        {/* drawer 2 */}
        <Drawer2
          open={drawerOpenCondition.drawer2}
          title="Client Fund Obligation"
          drawerLoading={drawerLoading}
          close={() => {
            selectData({
              data: null,
              column: null,
            });
            setDrawerData(null);
          }}
          data={{
            selectedData,
            drawerData,
          }}
        />
        {/* drawer 3 */}
        <Drawer3
          open={drawerOpenCondition.drawer3}
          title="Client Share Obligation"
          drawerLoading={drawerLoading}
          close={() =>
            selectData({
              data: null,
              column: null,
            })
          }
          data={{
            selectedData,
            drawerData,
          }}
        />
        {/* drawer 4 */}
        <Drawer4
          open={drawerOpenCondition.drawer4}
          title="Closure Details"
          drawerLoading={drawerLoading}
          close={() =>
            selectData({
              data: null,
              column: null,
            })
          }
          data={drawerData}
        />
        {/* drawer 5 */}
        <Drawer5
          open={drawerOpenCondition.drawer5}
          title="FundsMap Fund Obligation"
          drawerLoading={drawerLoading}
          close={() =>
            selectData({
              data: null,
              column: null,
            })
          }
          data={{
            selectedData,
            drawerData,
          }}
        />
        {/* drawer 6 */}
        <Drawer6
          open={drawerOpenCondition.drawer6}
          title="FundsMap Share Obligation"
          drawerLoading={drawerLoading}
          close={() =>
            selectData({
              data: null,
              column: null,
            })
          }
          data={{
            selectedData,
            drawerData,
          }}
        />
      </Box>
      <Dialog
        open={openDialog}
        scroll="paper"
        onClose={() => {
          setOpenDialog(false);
          setDisID("");
        }}
      >
        {dialogLoading ? (
          <Box
            sx={{
              height: "100%",
              width: "100%",
              minHeight: "500px",
              minWidth: "600px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            {processShareObligationsPayoutData ? (
              <>
                {payoutOptions === 0 ? (
                  <Box
                    sx={{
                      height: "100%",
                      width: "100%",
                      minHeight: "500px",
                      minWidth: "600px",
                      padding: "20px",
                    }}
                  >
                    <List>
                      <ListItem
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Typography fontWeight="bold">Upload ID :</Typography>
                        <Typography
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "60%",
                            textAlign: "right",
                          }}
                        >
                          {
                            processShareObligationsPayoutData?.uploadDetails
                              ?.uploadFileId
                          }
                        </Typography>
                      </ListItem>
                      <ListItem
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Typography fontWeight="bold">
                          Requested Payouts :
                        </Typography>
                        <Typography
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "60%",
                            textAlign: "right",
                          }}
                        >
                          {processShareObligationsPayoutData
                            ?.compositeSharePaymentEasiestDataList?.length +
                            processShareObligationsPayoutData?.errorList
                              ?.length}
                        </Typography>
                      </ListItem>
                      <ListItem
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Typography fontWeight="bold">
                          Successful Payouts :
                        </Typography>
                        <Typography
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "60%",
                            textAlign: "right",
                          }}
                        >
                          {
                            processShareObligationsPayoutData
                              ?.compositeSharePaymentEasiestDataList?.length
                          }
                        </Typography>
                      </ListItem>
                      <ListItem
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Typography fontWeight="bold">
                          Failed Payouts:
                        </Typography>
                        <Typography
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "60%",
                            textAlign: "right",
                          }}
                        >
                          {processShareObligationsPayoutData?.errorList?.length}
                        </Typography>
                      </ListItem>
                    </List>
                    {processShareObligationsPayoutData?.disMailInformation
                      ?.length > 0 && (
                      <>
                        <Typography>Consideration details</Typography>
                        <Box>
                          {JSON.stringify(
                            processShareObligationsPayoutData?.disMailInformation
                          )}
                        </Box>
                      </>
                    )}
                    <Accordion
                      defaultExpanded
                      style={{
                        width: "100%",
                        border: "1px solid #ddd",
                        borderRadius: "10px",
                        marginBottom: "20px",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography fontWeight="bold">Error IDs</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>ID</TableCell>
                              <TableCell>Reasons</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {processShareObligationsPayoutData?.errorList?.map(
                              (row, index) => (
                                <TableRow
                                  key={index}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell>
                                    <Tooltip title={row?.id}>
                                      <Typography>{row?.id}</Typography>
                                    </Tooltip>
                                  </TableCell>
                                  <TableCell>
                                    <Tooltip title={row?.reason}>
                                      <Typography>{row?.reason}</Typography>
                                    </Tooltip>
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      </AccordionDetails>
                    </Accordion>
                    <DialogActions>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                          setOpenDialog(false);
                          setProcessShareObligationsPayoutData(null);
                          setDisID("");
                        }}
                      >
                        Close
                      </Button>
                      {processShareObligationsPayoutData?.uploadDetails
                        ?.uploadFileId && (
                        <Button
                          startIcon={<DownloadIcon />}
                          variant="contained"
                          onClick={async () => {
                            const { data } = await api({
                              url: "/api/v2/admin/shares/shareObligations/payouts/easiest/files",
                              responseType: "blob",
                              params: {
                                uploadFileId:
                                  processShareObligationsPayoutData
                                    ?.uploadDetails?.uploadFileId,
                              },
                              headers: {
                                Authorization: `Bearer ${user.token}`,
                              },
                            });
                            // create an object URL to download the file
                            const url = window.URL.createObjectURL(
                              new Blob([data])
                            );
                            // create a link element and simulate a click
                            const link = document.createElement("a");
                            link.href = url;

                            link.setAttribute(
                              "download",
                              processShareObligationsPayoutData?.uploadDetails
                                ?.fileName
                            );
                            document.body.appendChild(link);
                            link.click();
                            // cleanup
                            document.body.removeChild(link);
                            window.URL.revokeObjectURL(url);
                          }}
                        >
                          Download File
                        </Button>
                      )}
                    </DialogActions>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      height: "100%",
                      width: "100%",
                      minHeight: "500px",
                      minWidth: "600px",
                      padding: "20px",
                    }}
                  >
                    <List>
                      <ListItem
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Typography fontWeight="bold">
                          Requested Payouts :
                        </Typography>
                        <Typography
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "60%",
                            textAlign: "right",
                          }}
                        >
                          {
                            processShareObligationsPayoutData?.requestedPayoutCount
                          }
                        </Typography>
                      </ListItem>
                      <ListItem
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Typography fontWeight="bold">
                          Successful Payouts :
                        </Typography>
                        <Typography
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "60%",
                            textAlign: "right",
                          }}
                        >
                          {
                            processShareObligationsPayoutData?.successfulPayoutGeneratedCount
                          }
                        </Typography>
                      </ListItem>
                      <ListItem
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Typography fontWeight="bold">
                          Failed Payouts:
                        </Typography>
                        <Typography
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "60%",
                            textAlign: "right",
                          }}
                        >
                          {processShareObligationsPayoutData?.errorPayoutCount}
                        </Typography>
                      </ListItem>
                    </List>
                    {processShareObligationsPayoutData?.disMailInformation
                      ?.length > 0 && (
                      <>
                        <Typography>Consideration details</Typography>
                        <Box>
                          {JSON.stringify(
                            processShareObligationsPayoutData?.disMailInformation
                          )}
                        </Box>
                      </>
                    )}
                    <Accordion
                      defaultExpanded
                      style={{
                        width: "100%",
                        border: "1px solid #ddd",
                        borderRadius: "10px",
                        marginBottom: "20px",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography fontWeight="bold">Error IDs</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>ID</TableCell>
                              <TableCell>Reasons</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {processShareObligationsPayoutData?.errorIds?.map(
                              (row, index) => (
                                <TableRow
                                  key={index}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell>
                                    <Tooltip title={row?.id}>
                                      <Typography>{row?.id}</Typography>
                                    </Tooltip>
                                  </TableCell>
                                  <TableCell>
                                    <Tooltip title={row?.reason}>
                                      <Typography>{row?.reason}</Typography>
                                    </Tooltip>
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      </AccordionDetails>
                    </Accordion>
                    <DialogActions>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                          setOpenDialog(false);
                          setProcessShareObligationsPayoutData(null);
                          setDisID("");
                        }}
                      >
                        Close
                      </Button>
                      {processShareObligationsPayoutData?.downloadData?.length >
                        0 && (
                        <Button
                          startIcon={<DownloadIcon />}
                          variant="contained"
                          onClick={() => {
                            console.log(
                              processShareObligationsPayoutData?.downloadData
                            );
                            jsonToCsvDownload(
                              processShareObligationsPayoutData?.downloadData
                            );
                          }}
                        >
                          Download CSV
                        </Button>
                      )}
                    </DialogActions>
                  </Box>
                )}
              </>
            ) : (
              <>
                <Tabs
                  value={payoutOptions}
                  onChange={(event, newValue) => setPayoutOptions(newValue)}
                  aria-label="basic tabs example"
                >
                  <Tab label="Easiest" {...a11yProps(0)} />
                  <Tab label="DIS" {...a11yProps(1)} />
                </Tabs>
                <TabPanel value={payoutOptions} index={0}>
                  <DialogTitle>Make Share Payout via EASIEST</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Once you place a payout request, you will generate a file
                      which needs to be uploaded to easiest portal. Share
                      Transfers will be made for the following.
                    </DialogContentText>
                    <Table
                      sx={{
                        border: "1px solid #ddd",
                        borderRadius: "10px",
                        margin: "10px 0 ",
                      }}
                      size="small"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>ISIN</TableCell>
                          <TableCell>Symbol</TableCell>
                          <TableCell>Quantity</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dialogTableRows?.map((row) => (
                          <TableRow key={row?.askFill.fillId + row?.bidFill.fillId}>
                            <TableCell>{row?.isin}</TableCell>
                            <TableCell>{row?.symbol}</TableCell>
                            <TableCell>{row?.quantity}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => {
                        setOpenDialog(false);
                        setDisID("");
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      onClick={easiestSharePayoutData}
                      disabled={dialogLoading}
                    >
                      Process
                    </Button>
                  </DialogActions>
                </TabPanel>
                <TabPanel value={payoutOptions} index={1}>
                  <DialogTitle>Make Share Payout</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Once you place a payout request, a share payout file will
                      be mailed to you. Please input the DIS slip no for which
                      you wish to make the request. Please confirm and share
                      with DP. The request will be made for the following.
                    </DialogContentText>
                    <Table
                      sx={{
                        border: "1px solid #ddd",
                        borderRadius: "10px",
                        margin: "10px 0 ",
                      }}
                      size="small"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>ISIN</TableCell>
                          <TableCell>Symbol</TableCell>
                          <TableCell>Quantity</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dialogTableRows?.map((row) => (
                          <TableRow key={row?.askFill.fillId + row?.bidFill.fillId}>
                            <TableCell>{row?.isin}</TableCell>
                            <TableCell>{row?.symbol}</TableCell>
                            <TableCell>{row?.quantity}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <TextField
                      autoFocus
                      margin="dense"
                      size="small"
                      id="name"
                      InputProps={{
                        inputProps: { min: 0 },
                      }}
                      value={disID}
                      onChange={(e) => setDisID(e.target.value)}
                      label="DIS ID"
                      type="number"
                      min="0"
                      fullWidth
                      variant="outlined"
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => {
                        setOpenDialog(false);
                        setDisID("");
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      onClick={processTransaction}
                      disabled={dialogLoading || !disID}
                    >
                      Process
                    </Button>
                  </DialogActions>
                </TabPanel>
              </>
            )}
          </>
        )}
      </Dialog>
    </>
  );
};

export default Transaction;
