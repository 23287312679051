import MaterialReactTable from "material-react-table";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { jsonToCsvDownload } from "../utils";

const TransactionTable = ({
  selectData,
  selectedData,
  tableData,
  openTransactionDialog,
}) => {
  return (
    <div>
      <MaterialReactTable
        enableRowSelection
        positionToolbarAlertBanner="bottom"
        enableColumnResizing
        columns={tableData?.columns}
        data={tableData?.data || []}
        state={{
          isLoading: tableData.isLoading,
          pagination: tableData?.pagination,
        }}
        enablePagination={true}
        manualPagination={true}
        onPaginationChange={tableData.setPagination}
        rowCount={tableData.rowCount}
        muiTableBodyCellProps={({ cell }) => ({
          onClick: () => {
            selectData({
              data: cell.row.original,
              column: cell.column.id,
            });
          },

          sx: {
            cursor: "pointer",
            textAlign: "center",
          },
        })}
        muiTableBodyRowProps={({ row }) => ({
          selected:
            (row?.original?.askFill?.fillId + row?.original?.bidFill?.fillId) === (selectedData?.data?.askFill?.fillId+selectedData?.data?.bidFill?.fillId) ,
        })}
        renderTopToolbarCustomActions={({ table }) => (
          <Box
            sx={{
              display: "flex",
              gap: "1rem",
              p: "0.5rem",
              padding: "10px 0",
              overflowX: "scroll",
              "&::webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            <Button
              sx={{
                flexShrink: 0,
              }}
              disabled={table.getPrePaginationRowModel().rows.length === 0}
              onClick={() => {
                jsonToCsvDownload(
                  table.getPrePaginationRowModel().rows?.map((row) => ({
                    "Transaction ID": row.original?.transactionId || "NA",
                    Quantity: row.original?.quantity || "NA",
                    Product: row.original?.isin || "NA",
                    Symbol: row.original?.symbol || "NA",
                    "Ask Price(₹)": row.original?.askFill?.price || "NA",
                    "Bid Price(₹)": row.original?.bidFill?.price || "NA",
                    "Transaction Status": row.original?.status || "NA",
                    "Funds Obligation Status":
                      row.original?.bidFill?.status || "NA",
                    "Share Obligation Status":
                      row.original?.askFill?.status || "NA",
                    "Closure Type": row.original?.closureDetails
                      ? row.original?.closureDetails.closureType
                      : "NA",
                    "Funds Payout Status": row.original?.closureDetails
                      ? row.original?.closureDetails
                          ?.fundsMapFundObligationState
                      : "NA",
                    "Share Payout Status": row.original?.closureDetails
                      ? row.original?.closureDetails
                          ?.fundsMapShareObligationState
                      : "NA",
                  })),
                  "transactions"
                );
              }}
              startIcon={<FileDownloadIcon />}
              variant="contained"
            >
              Export All Transactions
            </Button>

            <Button
              sx={{
                flexShrink: 0,
              }}
              disabled={
                !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
              }
              onClick={() =>
                jsonToCsvDownload(
                  table.getSelectedRowModel().rows?.map((row) => ({
                    "Transaction ID": row.original?.transactionId || "NA",
                    Quantity: row.original?.quantity || "NA",
                    Product: row.original?.isin || "NA",
                    Symbol: row.original?.symbol || "NA",
                    "Ask Price(₹)": row.original?.askFill?.price || "NA",
                    "Bid Price(₹)": row.original?.bidFill?.price || "NA",
                    "Transaction Status": row.original?.status || "NA",
                    "Funds Obligation Status":
                      row.original?.bidFill?.status || "NA",
                    "Share Obligation Status":
                      row.original?.askFill?.status || "NA",
                    "Closure Type": row.original?.closureDetails
                      ? row.original?.closureDetails.closureType
                      : "NA",
                    "Funds Payout Status": row.original?.closureDetails
                      ? row.original?.closureDetails
                          ?.fundsMapFundObligationState
                      : "NA",
                    "Share Payout Status": row.original?.closureDetails
                      ? row.original?.closureDetails
                          ?.fundsMapShareObligationState
                      : "NA",
                  })),
                  "transactions"
                )
              }
              startIcon={<FileDownloadIcon />}
              variant="contained"
            >
              Export Selected Transaction
            </Button>
            <Button
              sx={{
                flexShrink: 0,
              }}
              disabled={
                !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
              }
              onClick={() =>
                openTransactionDialog(
                  table.getSelectedRowModel().rows?.map((row) => row?.original)
                )
              }
              // startIcon={<FileDownloadIcon />}
              variant="contained"
            >
              Make Share Payout
            </Button>
          </Box>
        )}
      />
    </div>
  );
};

export default TransactionTable;
