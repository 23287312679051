import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import Table from "../components/Table";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItem,
  TableCell,
} from "@mui/material";

// icons
import { getWithdraw } from "../axios/withdraw/open";
import { useSelector } from "react-redux";
import { selectUser } from "../features/user/userSlice";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// components
import Main from "../components/layouts/Main";
import CustomDrawer from "../components/layouts/CustomDrawer";
import { processWithdraws } from "../axios/withdraw/process";
import { formatAmount, formatDate, isObjectEmpty } from "../utils";
import DrawerAccordion from "../components/layouts/DrawerAccordion";
import { useDispatch } from "react-redux";
import { showSnackbar } from "../features/snackbarSlice";

const Withdraw = () => {
  const [value, setValue] = useState(0);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [selectedData, setSelectedData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [processLoading, setProcessLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const dispatch = useDispatch();

  const user = useSelector(selectUser);

  const tableHeadings = [
    {
      accessorKey: "withdrawDetails.withdrawOrderId",
      header: "Order ID",
    },
    {
      accessorKey: "withdrawDetails.amount",
      header: "Amount (₹)",
    },
    {
      accessorFn: (row) => formatDate(row.withdrawDetails?.creationTimestamp),
      id: "withdrawDetails.creationTimestamp",
      header: "Transaction Time",
    },
    {
      accessorKey: "withdrawDetails.status",
      header: "Status",
    },
    {
      accessorFn: (row) =>
        row.withdrawDetails?.targetBankAccount
          ? row.withdrawDetails?.targetBankAccount?.ifsc +
            " " +
            row.withdrawDetails?.targetBankAccount?.bankAccountNumber
          : "NA",
      id: "withdrawDetails.targetBankAccount",
      header: "Bank Account",
      Cell: ({ cell }) => (cell.getValue() ? cell.getValue() : "--"),
    },
  ];
  const selectData = (data) => {
    setSelectedData(data);
  };

  const withdrawStatues = ["CREATED", "IN_PROCESS", "COMPLETED"];

  const getData = async () => {
    setIsLoading(true);
    const { data } = await getWithdraw(
      user.token,
      withdrawStatues[value],
      pagination.pageIndex,
      pagination.pageSize
    );
    if (data) {
      setTableData(data?.content);
      setRowCount(data?.totalElements);
      setPagination({
        ...pagination,
        pageCount: data?.totalPages,
      });
      // console.log(data?.content?.map((withdraw) => withdraw?.withdrawDetails));
    }
    setIsLoading(false);
  };

  const processWithdraw = async (ids) => {
    setProcessLoading(true);
    const { data, error } = await processWithdraws(user.token, ids);
    if (data) {
      dispatch(
        showSnackbar({
          type: "success",
          message: "Process successful",
        })
      );
      data?.withdraws?.map((withdraw) => {
        setTableData(
          tableData?.filter(
            (i) =>
              i?.withdrawDetails?.withdrawOrderId !=
              withdraw?.withdrawDetails?.withdrawOrderId
          )
        );
      });
      selectData(null);
    }
    if (error) {
      console.log(error);
      dispatch(
        showSnackbar({
          type: "error",
          message: "Process Failed",
        })
      );
    }
    setProcessLoading(false);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  useEffect(() => {
    setPagination({ ...pagination, pageIndex: 0 });
    getData();
    setSelectedData(null);
  }, [value, pagination.pageIndex, pagination.pageSize]);
  return (
    <>
      <Main open={selectedData ? true : false}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={value} onChange={(e, value) => setValue(value)}>
            <Tab label="Open" {...a11yProps(0)} />
            <Tab label="Processing" {...a11yProps(1)} />
            <Tab label="Completed" {...a11yProps(2)} />
          </Tabs>
        </Box>

        <Table
          isLoading={isLoading}
          selectData={selectData}
          selectedData={selectedData}
          tableHeadings={tableHeadings}
          processWithdraw={processWithdraw}
          tableData={tableData}
          pagination={pagination}
          setPagination={setPagination}
          rowCount={rowCount}
        />
      </Main>
      <CustomDrawer
        open={selectedData ? true : false}
        onClose={() => setSelectedData(null)}
        title="Withdraw Detail"
      >
        <Typography variant="h4" fontWeight="bold" color="#333" padding="20px">
          ₹ {formatAmount(selectedData?.withdrawDetails?.amount)}
        </Typography>

        <Box
          sx={
            selectedData?.withdrawDetails?.status === "CREATED"
              ? {
                  maxHeight: "calc(100vh - 250px)",
                  overflowY: "scroll",
                  marginBottom: "20px",
                }
              : {}
          }
        >
          <DrawerAccordion
            title="Withdraw Details"
            data={[
              {
                key: "UTR Number",
                value: selectedData?.withdrawDetails?.utr
                  ? selectedData?.withdrawDetails?.utr
                  : "NA",
              },
              {
                key: "Withdraw ID",
                value: selectedData?.withdrawDetails?.withdrawOrderId,
              },
              {
                key: "Amount",
                value: `₹ ${formatAmount(
                  selectedData?.withdrawDetails?.amount
                )}`,
              },
              {
                key: "Creation Time",
                value: formatDate(
                  selectedData?.withdrawDetails?.creationTimestamp
                ),
              },
              {
                key: "Status",
                value: selectedData?.withdrawDetails?.status,
              },
            ]}
          />
          <DrawerAccordion
            title="Payment Details"
            data={
              selectedData?.withdrawDetails?.status === "CREATED"
                ? []
                : [
                    {
                      key: "Payment ID",
                      value: selectedData?.payment?.paymentId,
                    },
                    {
                      key: "Payment Gateway",
                      value: selectedData?.payment?.payoutSystem,
                    },
                    {
                      key: "Payment Gateway ID",
                      value: selectedData?.payment?.paymentSystemId,
                    },
                    {
                      key: "Payment Created By User ID",
                      value: selectedData?.payment?.processInitiationUserId,
                    },
                    {
                      key: "Payment Creation Time",
                      value: formatDate(
                        selectedData?.payment?.processCreationTime
                      ),
                    },
                    {
                      key: "Target Bank IFSC",
                      value: selectedData?.payment?.targetBankAccountId?.ifsc,
                    },
                    {
                      key: "Target Bank Account No",
                      value:
                        selectedData?.payment?.targetBankAccountId
                          .bankAccountNumber,
                    },
                    {
                      key: "Beneficiary Name on Bank Account",
                      value: selectedData?.payment?.targetUserNameOnBankAccount,
                    },
                  ]
            }
            message="Payment Details not yet available as no payout is made"
            messageType="info"
          />
          <DrawerAccordion
            title="User Contact Details"
            data={[
              {
                key: "Name",
                value: selectedData?.user?.fullName,
              },
              {
                key: "User ID",
                value: selectedData?.user?.userId,
              },
              {
                key: "Email ID",
                value: selectedData?.user?.emailId,
              },
              {
                key: "Contact No",
                value: `${selectedData?.user?.countryCode} ${selectedData?.user?.mobileNumber}`,
              },
            ]}
            message="Payment Details not yet available as no payout is made"
          />
        </Box>
        <Divider />
        {selectedData?.withdrawDetails?.status === "CREATED" && (
          <Button
            variant="contained"
            size="large"
            fullWidth
            color="primary"
            onClick={() =>
              processWithdraw([selectedData?.withdrawDetails?.withdrawOrderId])
            }
            disabled={processLoading}
          >
            {processLoading ? (
              <CircularProgress size={30} padding="20px 0" />
            ) : (
              "Process Withdraw"
            )}
          </Button>
        )}
      </CustomDrawer>
    </>
  );
};

export default Withdraw;
