import { api } from "..";

export const getOrders = async (token, type = "OPEN") => {
  try {
    const { data } = await api({
      url: `api/v1/dealer/orders/views/dashboard`,
      params: {
        type,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return {
      data,
    };
  } catch (error) {
    return {
      error,
    };
  }
};
