import React from "react";
import MaterialReactTable from "material-react-table";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { jsonToCsvDownload } from "../utils";

//defining columns outside of the component is fine, is stable
const data = [
  {
    name: {
      firstName: "John",
      lastName: "Doe",
    },
    address: "261 Erdman Ford",
    city: "East Daphne",
    state: "Kentucky",
  },
  {
    name: {
      firstName: "Jane",
      lastName: "Doe",
    },
    address: "769 Dominic Grove",
    city: "Columbus",
    state: "Ohio",
  },
  {
    name: {
      firstName: "Joe",
      lastName: "Doe",
    },
    address: "566 Brakus Inlet",
    city: "South Linda",
    state: "West Virginia",
  },
  {
    name: {
      firstName: "Kevin",
      lastName: "Vandy",
    },
    address: "722 Emie Stream",
    city: "Lincoln",
    state: "Nebraska",
  },
  {
    name: {
      firstName: "Joshua",
      lastName: "Rolluffs",
    },
    address: "32188 Larkin Turnpike",
    city: "Charleston",
    state: "South Carolina",
  },
];
const columns = [
  {
    accessorKey: "id",
    header: "ID",
    size: 40,
  },
  {
    accessorKey: "firstName",
    header: "First Name",
    size: 120,
  },
  {
    accessorKey: "lastName",
    header: "Last Name",
    size: 120,
  },
  {
    accessorKey: "company",
    header: "Company",
    size: 300,
  },
  {
    accessorKey: "city",
    header: "City",
  },
  {
    accessorKey: "country",
    header: "Country",
    size: 220,
  },
];

const Table = ({
  isLoading,
  selectData,
  selectedData,
  tableHeadings,
  tableData,
  processWithdraw,
  pagination,
  setPagination,
  process,
  rowCount,
}) => {
  const handleExportData = () => {
    csvExporter.generateCsv(data);
  };

  return (
    <MaterialReactTable
      columns={tableHeadings}
      data={tableData}
      enableRowSelection
      enableColumnResizing
      positionToolbarAlertBanner="bottom"
      initialState={{
        pagination: {
          pageSize: 10,
          pageIndex: 0,
        },
      }}
      state={{ pagination, isLoading: isLoading }}
      onPaginationChange={setPagination}
      enablePagination={true}
      manualPagination={true}
      rowCount={rowCount}
      muiTableBodyRowProps={({ row }) => ({
        onClick: () => selectData(row.original),
        selected:
          row?.original?.withdrawDetails?.withdrawOrderId ===
          selectedData?.withdrawDetails?.withdrawOrderId,
        sx: {
          cursor: "pointer",
        },
      })}
      renderTopToolbarCustomActions={({ table }) => (
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            p: "0.5rem",
            padding: "10px 0",
            overflowX: "scroll",
            "&::webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <Button
            sx={{
              flexShrink: 0,
            }}
            disabled={table.getPrePaginationRowModel().rows.length === 0}
            onClick={() =>
              jsonToCsvDownload(table.getPrePaginationRowModel().rows)
            }
            startIcon={<FileDownloadIcon />}
            variant="contained"
          >
            Export All Withdraw
          </Button>

          <Button
            sx={{
              flexShrink: 0,
            }}
            disabled={
              !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
            }
            onClick={() => jsonToCsvDownload(table.getSelectedRowModel().rows)}
            startIcon={<FileDownloadIcon />}
            variant="contained"
          >
            Export Selected Withdraw
          </Button>
          {process && (
            <Button
              sx={{
                flexShrink: 0,
              }}
              disabled={
                !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
              }
              onClick={() =>
                processWithdraw(
                  table
                    .getSelectedRowModel()
                    .rows?.map(
                      (row) => row.original?.withdrawDetails?.withdrawOrderId
                    )
                )
              }
              variant="contained"
            >
              Process Selected Withdraw
            </Button>
          )}
        </Box>
      )}
    />
  );
};

export default Table;
