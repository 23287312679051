import styled from "@emotion/styled";

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    // flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // [theme.breakpoints.up("md")]: {
    //   padding: theme.spacing(3),
    // },
    // [theme.breakpoints.down("md")]: {
    //   padding: theme.spacing(1),
    // },
    marginRight: `0px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: "500px",
    }),
  })
);

export default Main;
