import { api } from "..";

export const getWithdraw = async (
  token,
  state = "CREATED",
  pageNo = 0,
  pageSize = 10
) => {
  try {
    const { data } = await api({
      url: `/api/v2/admin/funds/withdraws?state=${state}&pageNo=${pageNo}&pageSize=${pageSize}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return {
      data,
    };
  } catch (error) {
    return {
      error,
    };
  }
};
