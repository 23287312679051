import { api } from "..";

export const getTransactions = async (token, 
  state = "INPROCESS", 
  pageNo = 0,
  pageSize = 10) => {
  try {
    const { data } = await api({
      url: `/api/v1/admin/transactions?state=${state}&pageNo=${pageNo}&pageSize=${pageSize}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return {
      data,
    };
  } catch (error) {
    return {
      error,
    };
  }
};
