import { configureStore } from "@reduxjs/toolkit";

// importing slices
import userSlice from "../features/user/userSlice";
import snackbarSlice from "../features/snackbarSlice";

export const store = configureStore({
  reducer: {
    user: userSlice,
    snackbar: snackbarSlice,
  },
});
