import { createSlice } from "@reduxjs/toolkit";

const initialState = null;

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (state, action) => {
      state = action.payload;
      return state;
    },
    logout: (state) => {
      state = initialState;
      return state;
    },
  },
});

export { userSlice };

// Action creators are generated for each case reducer function
export const { login: loginUser, logout: logoutUser } = userSlice.actions;

export const selectUser = (state) => state.user;

export default userSlice.reducer;
